import React from "react";

const Main = () => {
  return (
    <div className="max-w-6xl px-12 mx-auto pt-14 pb-14">
      <div className="text-5xl font-thin text-white lg:text-6xl">
        <span>Welcome to T-Drug!</span>
      </div>
      <div className="mt-10 font-thin text-white lg:text-lg">
        <p>
          T-Drug is an interactive web-tool that aims to repurpose useful drugs
          that can target a specific therapeutic gene in disease rather than
          generally regulate a batch of disease-related genes. The construction
          of this tool is based on our previously proposed profile-based drug
          repositioning method which performs an integrated analysis of
          transcriptomics signature profiles of human cells induced by drug
          treatment and genetic perturbation. In T-Drug, users can query a
          single gene or drug, and this tool will provide the potential drugs
          that can inhibit or activate the protein level of the queried gene or
          the potential genes that can be targeted by the queried drug. The
          predicted drug-target associations are ranked based on the confidence
          scores. In current version, T-Drug can provide a widespread prediction
          for more than 8,000 targetable genes and 30,000 drugs. We believe that
          T-Drug would be a powerful tool to discover the new therapeutic
          targets and chemotherapy regimens for the disease treatment.
        </p>
        <span></span>
      </div>
    </div>
  );
};

export default Main;
