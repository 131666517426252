import { Form } from "antd";
import { useStore } from "../../../reducers/zustand/store";

const ExampleArea = () => {
  const { formFields, patchFormFields } = useStore((state: any) => ({
    formFields: state.formFields,
    patchFormFields: state.patchFormFields,
  }));

  const changeStateForCase1 = () => {
    patchFormFields({
      geneOrDrug: "MCM6",
      pertType: "SH",
      isActivator: false,
      cellLine: "A549",
      absoluteSimilarity: 0.4,
    });
  };
  const changeStateForCase2 = () => {
    patchFormFields({
      geneOrDrug: "mitoxantrone (BRD-K21680192)",
      pertType: "SH",
      isActivator: false,
      cellLine: "A549",
      absoluteSimilarity: 0.4,
    });
  };
  return (
    <Form.Item colon={false} label={<p>Examples: </p>}>
      <div className="flex flex-row gap-1">
        <button
          style={{ color: "#1890ff", textDecoration: "underline" }}
          onClick={changeStateForCase1}
        >
          Case 1
        </button>
        <button
          style={{ color: "#1890ff", textDecoration: "underline" }}
          onClick={changeStateForCase2}
        >
          Case 2
        </button>
      </div>
    </Form.Item>
  );
};
export default ExampleArea;
