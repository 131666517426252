import React from 'react';

const AccordionItem = (props: any) => {
  return (
    <div className="flex flex-col ">
      <div className="flex items-center space-x-4 text-md font-normal">
        <props.icon /> <span>{props.title}</span>
      </div>
    </div>
  );
};
export default AccordionItem;
