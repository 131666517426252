import { Button } from "antd";
import { useRef } from "react";
import { useStore } from "../../../reducers/zustand/store";
import Export from "../Export";

export function QueryButton() {
  const { viewType, setViewType, nodes } = useStore((state: any) => ({
    viewType: state.viewType,
    setViewType: state.setViewType,
    nodes: state.nodes,
  }));
  const graphWrapperRef = useRef();

  const toggleViewType = () => {
    setViewType(viewType === "Graph" ? "Edge Table" : "Graph");
  };

  return (
    <div
      className={`z-10 flex flex-col gap-2 ${
        viewType === "Graph" && "absolute"
      }`}
    >
      <div className="flex content-between gap-2 mt-6 ml-10">
        <Button
          onClick={toggleViewType}
          className="px-6 py-5 shadow-lg hover:shadow-none"
        >
          {viewType === "Graph" ? "Table View" : "Graph View"}
        </Button>
        <Export
          graphWrapperRef={graphWrapperRef}
          visualize={false}
          coloredField={undefined}
        />
      </div>
      <div className="mx-12 w-60">
        {viewType === "Graph" && nodes?.length > 50 && (
          <p>
            Graph view shows up to 50 nodes. Switch to table view or export to
            see all results
          </p>
        )}
      </div>
    </div>
  );
}

export default QueryButton;
