import React from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { store } from "../../utils/types";
import SizeAwareGraph from "./SizeAwareGraph";
import { useStore } from "../../reducers/zustand/store";
import { QueryButton } from "./queryArea/queryButton";

type Props = {
  doQueryStatus: string;
  doQueryPayload: any;
  graphWrapperRef: any;
};

const QueryResultArea = ({
  doQueryStatus,
  doQueryPayload,
  graphWrapperRef,
}: Props) => {
  const { loading } = useStore((state: any) => ({
    loading: state.loading,
  }));

  const size = {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  };
  return (
    <>
      <div className="w-full bg-gray-50" style={{ minHeight: "100vh" }}>
        <div className="w-full h-full bg-gray-50">
          {loading ? (
            <Spin
              size="large"
              tip="Loading..."
              style={{
                color: "rgba(59, 130, 246)",
                margin: "10% 50%",
              }}
            />
          ) : (
            <div>
              <QueryButton />
              <SizeAwareGraph graphWrapperRef={graphWrapperRef} size={size} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: store) => ({
  doQueryStatus: state.query.doQueryOp.status,
  doQueryPayload: state.query.doQueryOp.payload,
  //visualize: state.query.requestedQueryDetails?.visualize,
});

export default connect(mapStateToProps)(QueryResultArea);
