import { SearchOutlined } from "@ant-design/icons";
import { Button, Form, Select, Switch } from "antd";
// @ts-ignore
import { connect } from "react-redux";
import {
  setBackgroundType,
  setColoredField,
  setLinkVisiblity,
  setViewType,
} from "../../actions/graph-modals";
import { backgroundTypes, viewTypes } from "../../reducers/graph-modals";
import { useStore } from "../../reducers/zustand/store";
import { ColoredField, Dispatch, store } from "../../utils/types";

const { Option } = Select;

const ViewOptions = ({ backgroundType, setBackgroundTypeAction }: any) => {
  const {
    setViewType,
    viewType,
    areLinksVisible,
    setAreLinksVisible,
    zoomToFit,
    zoomToFitToggler,
  } = useStore((state: any) => ({
    links: state.links,
    nodes: state.nodes,
    viewType: state.viewType,
    setViewType: state.setViewType,
    setNodes: state.setNodes,
    setLinks: state.setLinks,
    areLinksVisible: state.areLinksVisible,
    setAreLinksVisible: state.setAreLinksVisible,
    zoomToFit: state.zoomToFit,
    zoomToFitToggler: state.zoomToFitToggler,
  }));

  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="">
          {viewType === viewTypes[0] && (
            <div className="flex flex-col pl-4 space-y-4">
              <div className="flex justify-between">
                <span className="mr-2">Show Links </span>
                <div>
                  <Switch
                    size="small"
                    checked={areLinksVisible}
                    onChange={(checked: boolean) => setAreLinksVisible(checked)}
                  />
                </div>
              </div>
              <div className="flex justify-between ">
                <span className="mr-2">Zoom to fit </span>
                <div>
                  <Button
                    size="small"
                    type="primary"
                    shape="circle"
                    icon={<SearchOutlined />}
                    onClick={() => zoomToFit(!zoomToFitToggler)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: store) => ({
  areLinksVisible: state.graphModals.areLinksVisible,
  viewType: state.graphModals.viewType,
  backgroundType: state.graphModals.backgroundType,
  coloredField: state.graphModals.coloredField,
  queryResponse: state.query.queryResponse,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setAreLinksVisible: (visible: boolean) => dispatch(setLinkVisiblity(visible)),
  setViewTypeAction: (viewType: string) => dispatch(setViewType(viewType)),
  setBackgroundTypeAction: (backgroundType: string) =>
    dispatch(setBackgroundType(backgroundType)),
  setColoredFieldAction: (coloredField?: ColoredField) =>
    dispatch(setColoredField(coloredField)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ViewOptions);
