export const cellLineToDiseaseMapping = {
  A375: "skin cancer",
  A549: "lung cancer",
  H1299: "lung cancer",
  HA1E: "normal kidney tissue",
  HCC515: "lung cancer",
  HEK293: "normal kidney tissue",
  HEK293T: "normal kidney tissue, derived from HEK293",
  HELA: "cervical cancer",
  HEPG2: "liver cancer",
  HT29: "colon cancer",
  HUH7: "liver cancer",
  LNCAP: "prostate cancer",
  MCF7: "breast cancer",
  PC3: "prostate cancer",
  U2OS: "bone cancer",
  VCAP: "prostate cancer",
  ASC: "normal soft tissue",
  JURKAT: "luekemia",
  NPC: "normal stem cell",
  SHSY5Y: "neuroblastoma",
  SKL: "normal soft tissue",
  SW480: "colon cancer",
  AGS: "gastric cancer",
  BICR6: "head and neck cancer",
  ES2: "ovarian cancer",
  HUH751: "hepatoma",
  K562: "leukemia",
  SNGM: "endometrial cancer",
  THP1: "luekemia",
  U251MG: "brain cancer",
  U937: "lymphoma",
  YAPC: "pancreatic cancer",
};

export const generateCellLineWithDiseases = (cellLine: string) => {
  const cellLineDiseaseTuple = Object.entries(cellLineToDiseaseMapping).find(
    (disease) => disease[0] === cellLine
  );

  return `${
    cellLineDiseaseTuple
      ? cellLineDiseaseTuple[0] + " (" + cellLineDiseaseTuple[1] + ")"
      : cellLine
  }`;
};
