import { Col, InputNumber, Row, Slider } from "antd";
import React from "react";

type Props = {
  min: number;
  max: number;
  step: number;
  value?: number;
  onChange: any;
  defaultValue: number;
};
const InputNumberWithSlider = ({ min, max, step, value, onChange }: Props) => {
  return (
    <Row className="flex flex-wrap justify-between flex-1">
      <Col flex="auto" className="mr-2">
        <Slider
          min={min}
          max={max}
          onChange={onChange}
          value={typeof value === "number" ? value : 0}
          step={step}
          defaultValue={0.4}
        />
      </Col>
      <Col flex="5rem">
        <InputNumber
          min={min}
          max={max}
          className=""
          value={value}
          step={step}
          onChange={onChange}
          defaultValue={0.4}
        />
      </Col>
    </Row>
  );
};

export default InputNumberWithSlider;
