import { useState } from "react";
import { Collapse } from "antd";
import AccordionItem from "./AccordionItem";
import { IoIosOptions } from "react-icons/io";
import { MdLegendToggle } from "react-icons/md";
import LegendBar from "./LegendBar";
import ViewOptions from "./ViewOptions";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { useStore } from "../../reducers/zustand/store";
const { Panel } = Collapse;

const Accordion = ({ graphWrapperRef }: any) => {
  const [collapsed, setCollapsed] = useState(true);
  const { viewType } = useStore((state: any) => ({
    viewType: state.viewType,
  }));

  const toggleSider = () => {
    setCollapsed((collapse) => !collapse);
  };
  return viewType === "Graph" ? (
    <div className="relative flex-row w-full md:top-0 md:right-0 md:w-auto">
      <div
        onClick={toggleSider}
        className="absolute self-start hidden p-2 mt-4 mr-4 -ml-12 text-xs bg-white rounded-lg shadow-md cursor-pointer md:flex"
      >
        {collapsed ? <LeftOutlined /> : <RightOutlined />}
      </div>

      <div
        className={
          "flex flex-row shadow-md w-96 md:max-w-sm overflow-hidden rounded-bl-xl bg-white"
        }
        style={{
          height: "",
          display: collapsed ? "none" : "flex",
        }}
      >
        <Collapse className="w-96" accordion expandIconPosition="right" ghost>
          <Panel
            header={<AccordionItem title="View Options" icon={IoIosOptions} />}
            key="1"
          >
            <ViewOptions />
          </Panel>
          <Panel
            header={<AccordionItem title="Legend" icon={MdLegendToggle} />}
            key="2"
          >
            <LegendBar />
          </Panel>
        </Collapse>
      </div>
    </div>
  ) : null;
};

export default Accordion;
