import React from "react";
import { Card } from "antd";
import Draggable from "react-draggable";
import { connect } from "react-redux";
import { removeLink, removeNode } from "../../actions/graph-modals";
import { Dispatch, func, LinkWithNodes, Node, store } from "../../utils/types";
import { CloseOutlined } from "@ant-design/icons";
import { cropNode, nodesInfoSorter } from "../../utils/helpers";
import { getLinkComponent } from "../../utils/table-columns";
import { useHistory } from "react-router-dom";
import {
  patchCurrentQueryDetiails,
  queryWithCurrentQueryDetails,
} from "../../actions/query";
import ShowMore from "react-show-more-text";
import { useStore } from "../../reducers/zustand/store";

type Props = {
  nodes: Node[];
  links: LinkWithNodes[];
  removeNodeAction: func;
  removeLinkAction: func;
  patchCurrentQueryDetailsAction: func;
  queryWithCurrentQueryDetailsAction: func;
};
const GraphInformationModal = ({
  nodes,
  links,
  removeNodeAction,
  removeLinkAction,

  patchCurrentQueryDetailsAction,
  queryWithCurrentQueryDetailsAction,
}: Props) => {
  const history = useHistory();
  const { formFields } = useStore((state: any) => ({
    formFields: state.formFields,
  }));
  const isCellLineAll = formFields.cellLine === "All";
  const [search, setSearch] = React.useState<String[]>([]);
  const handleAdd = (node: Node) => {
    setSearch([...search, node.ID]);
    queryWithCurrentQueryDetailsAction(history);
  };

  const handleRemove = (node: Node) => {
    patchCurrentQueryDetailsAction({
      search: search.filter((symbol: String) => symbol !== node.ID),
    });
    queryWithCurrentQueryDetailsAction(history);
  };
  const generateReadableLabel = (label: string) => {
    if (label === "isGene") return "type";
    if (isCellLineAll && label === "URL") return "URL";
    if (label === "medianCorrelation" || label === "correlation")
      return "Confidence Score";
    return label.replace(/_/g, " ");
  };

  const generateValue = (node: Node, field: string) => {
    return field === "isGene"
      ? // @ts-ignore

        node["isGene"]
        ? // @ts-ignore

          node["isGene"] === "true"
          ? "Gene"
          : "Subsequence"
        : "Drug"
      : // @ts-ignore

        node[field];
  };
  return (
    <>
      {nodes.map((node) => {
        return (
          <Draggable
            key={node.id}
            defaultPosition={{
              x: 40 + nodes.length * 10,
              y: 40 + nodes.length * 100,
            }}
            position={undefined}
            bounds="parent"
            handle=".handle"
            scale={1}
          >
            <Card
              title={
                isCellLineAll !== null ? (
                  node.link ? (
                    <a
                      href={node.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {node.search_index}
                    </a>
                  ) : (
                    node.search_index
                  )
                ) : (
                  node.search_index
                )
              }
              extra={
                <>
                  <span
                    className="text-lg cursor-pointer"
                    onClick={() => removeNodeAction(node.id)}
                  >
                    <CloseOutlined />
                  </span>
                </>
              }
              className="relative max-h-full cursor-move graph-node-modal handle"
            >
              <div className="flex-1 space-y-4 overflow-y-auto">
                {Object.keys(cropNode(node))
                  .filter(
                    (key) =>
                      key !== "ID" &&
                      key !== "search_index" &&
                      key !== "label" &&
                      key !== "x" &&
                      key !== "y" &&
                      key !== "vx" &&
                      key !== "vy" &&
                      key !== "fx" &&
                      key !== "fy" &&
                      key !== "do_not_show" &&
                      key !== "label_special_pert" &&
                      key !== "link"
                  )
                  .map((field: any) => (
                    <div key={field} className="flex flex-row">
                      <span className="mr-2 font-bold">{`${generateReadableLabel(
                        field
                      )}:`}</span>

                      {
                        // @ts-ignore
                        generateValue(node, field)
                      }
                    </div>
                  ))}
              </div>
            </Card>
          </Draggable>
        );
      })}

      {links.map((link: any) => (
        <Draggable
          key={link.start + " " + link.end}
          defaultPosition={{
            x: 80 + links.length * 10,
            y: 80 + links.length * 50,
          }}
          position={undefined}
          bounds="parent"
          handle=".handle"
          scale={1}
        >
          <Card
            title={link.source.label + " <-> " + link.target.label}
            extra={
              <>
                <span
                  className="text-lg cursor-pointer"
                  onClick={() => removeLinkAction(link.start, link.end)}
                >
                  <CloseOutlined />
                </span>
              </>
            }
            className="cursor-move graph-node-modal handle"
          >
            {isCellLineAll ? (
              <>
                <p>Confidence Score: {link.medianCorrelation.toFixed(4)} </p>
                <p>Effect Type: {link.effect_type} </p>
                <p>Hit Rate (%): {(link.hit_rate * 100).toFixed(2)} </p>
                <p>Number Of Hits: {link.numberOfHits} </p>
                <p>Cell Line: {link.cell_lines?.join("/ ")} </p>
              </>
            ) : (
              <>
                <p>Confidence Score: {link.correlation.toFixed(4)}</p>
                <p>Cell Line: {link.cell_line}</p>
                <p>Effect Type: {link.effect_type}</p>
              </>
            )}
          </Card>
        </Draggable>
      ))}
    </>
  );
};
const mapStateToProps = (state: store) => ({
  nodes: state.graphModals.nodes,
  links: state.graphModals.links,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  removeNodeAction: (nodeId: number | string) => dispatch(removeNode(nodeId)),
  removeLinkAction: (source: number | string, target: number | string) =>
    dispatch(removeLink(source, target)),
  patchCurrentQueryDetailsAction: (updatedFields: any) =>
    dispatch(patchCurrentQueryDetiails(updatedFields)),
  queryWithCurrentQueryDetailsAction: (history: any) =>
    dispatch(queryWithCurrentQueryDetails(history)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GraphInformationModal);
