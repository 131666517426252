import React from "react";
import { Col, Divider, Row } from "antd";
import { linkColors } from "../../utils/helpers";
import { connect } from "react-redux";
import { Dispatch, store } from "../../utils/types";
import { setHoveredInfoType } from "../../actions/graph-modals";

const maxCharacterCount = 144;

const LegendColor = ({
  infoType,
  coloredField,
  setHoveredInfoTypeAction,
  noInfo,
}: any) => {
  return (
    <Col key={infoType} span={12}>
      <div className="w-full">
        <div
          className="legend-node"
          style={{
            backgroundColor: noInfo ? "#ddd" : coloredField.colors[infoType][0],
          }}
        ></div>
        <div className="relative my-3 mr-4 text-xs font-light break-words group">
          {infoType.length < maxCharacterCount ? (
            infoType
          ) : (
            <>
              <span>{infoType.substring(0, maxCharacterCount) + "..."}</span>
              <span className="absolute z-50 hidden p-2 break-words bg-gray-100 rounded-md group-hover:block -top-2 -left-2">
                {infoType}
              </span>
            </>
          )}
        </div>
      </div>
    </Col>
  );
};

const LegendBar = ({ coloredField, setHoveredInfoTypeAction }: any) => {
  return (
    <Row>
      <Col span={24}>
        <Row>
          {Object.keys(linkColors).map((linkType) => (
            <Col key={linkType} span={12} className="flex justify-center">
              <div>
                <div
                  className="legend-link"
                  style={{
                    backgroundColor: linkColors[linkType],
                    width: 50,
                    height: 50,
                    borderRadius: "50%",
                  }}
                ></div>
                <span className="block mx-auto text-sm font-light">
                  {linkType}
                </span>
              </div>
            </Col>
          ))}
        </Row>
      </Col>
      <Col span={24} className="mt-4">
        <Row className="wrap">
          {coloredField && (
            <Col key={coloredField.key} span={24}>
              <Divider orientation="left" plain>
                Colored by {coloredField.key}
              </Divider>
            </Col>
          )}
          {coloredField && <LegendColor noInfo={true} infoType="No Info" />}
          {coloredField &&
            Object.keys(coloredField.colors).map((infoType) => (
              <LegendColor
                key={infoType}
                infoType={infoType}
                coloredField={coloredField}
                setHoveredInfoTypeAction={setHoveredInfoTypeAction}
              />
            ))}
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: store) => ({
  coloredField: state.graphModals.coloredField,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setHoveredInfoTypeAction: (hoveredInfoType: string) =>
    dispatch(setHoveredInfoType(hoveredInfoType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LegendBar);
