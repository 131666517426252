import { ColoredField, LinkWithNodes, Node } from '../utils/types';

export const removeNode = (nodeId: number | string) => ({
  type: 'graph-modals/REMOVE_NODE',
  nodeId,
});

export const removeLink = (
  sourceId: number | string,
  targetId: number | string
) => ({
  type: 'graph-modals/REMOVE_LINK',
  sourceId,
  targetId,
});

export const addNode = (node: Node) => ({
  type: 'graph-modals/ADD_NODE',
  node,
});

export const addLink = (link: LinkWithNodes) => ({
  type: 'graph-modals/ADD_LINK',
  link,
});

export const resetModals = () => ({
  type: 'graph-modals/RESET_MODALS',
});

export const setLinkVisiblity = (visible: boolean) => ({
  type: 'graph-modals/SET_LINK_VISIBLE',
  visible: visible,
});

export const zoomToFit = () => ({
  type: 'graph-modals/ZOOM_TO_FIT',
});

export const setViewType = (viewType: string) => ({
  type: 'graph-modals/SET_VIEW_TYPE',
  viewType,
});

export const setBackgroundType = (backgroundType: string) => ({
  type: 'graph-modals/SET_BACKGROUND_TYPE',
  backgroundType,
});

export const setColoredField = (coloredField?: ColoredField) => ({
  type: 'graph-modals/SET_COLORED_FIELD',
  coloredField,
});

export const setHoveredInfoType = (hoveredInfoType: string) => ({
  type: 'graph-modals/SET_HOVERED_INFO_TYPE',
  hoveredInfoType,
});
