import { QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Input, Modal, Row, Upload } from "antd";
import React, { useCallback, useState } from "react";
import { getUploadLink, uploadZipFile } from "../../services/upload";
import HelpModal from "../common-components/HelpModal";
const { TextArea } = Input;

const fields = [
  {
    field: "projectName",
    label: "Project Name",
    type: "input",
    isModal: true,
    modalText:
      "Please describe your project name (e.g. MCM6_knockdown_project).",
  },
  {
    field: "experimentalDesign",
    label: "Experimental Design",
    type: "input",
    isModal: true,
    modalText:
      "Please describe the experimental design of your data (e.g. To explore the function of MCM6 in lung cancer, we knocked down this gene by shRNA in the A549 cell line). ",
  },
  {
    field: "dataProcessing",
    label: "Data Processing",
    type: "input",
    isModal: true,
    modalText:
      "How did you generate the count and TPM/RPKM/FPKM profiles from the raw data (fastq files)? Please describe the reference genome version and the methods used for alignment and quantification. Example 1: HISAT2 was used for alignment and HTSeq V0.11.2 was used for quantification. Human reference genome: GRCh38. Example 2: The count and TPM values were quantified by Kallisto. The human reference genome Ensembl V106 was used.",
  },
  {
    field: "citation",
    label: "Citation",
    type: "input",
    isModal: true,
    modalText: "Please provide the publication information based on your data.",
  },
  {
    field: "accessionNumberOfData",
    label: "Accession Number of Data",
    type: "input",
    isModal: true,
    modalText:
      "Please provide the accession number if you have uploaded your data in a public database (e.g. GSEXXX in GEO).",
  },
  { field: "name", label: "Contact Name", type: "input", isModal: false },
  { field: "affiliation", label: "Affiliation", type: "input", isModal: false },
  { field: "email", label: "Email", type: "input", isModal: false },
];

const modalCount = fields.reduce((acc, curr) => {
  return curr.isModal ? acc + 1 : acc;
}, 0);

const UploadPage = () => {
  const [modalsVisibility, setModalsVisibility] = useState(
    Array(modalCount).fill(false)
  );
  const [inputFields, setInputFields] = useState<any>({});
  const [fileList, setFileList] = useState<any>([]);
  const [uploading, setUploading] = useState({ status: "init", payload: "" }); // init, loading, success, error

  const validateEmail = useCallback((email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }, []);

  const showError = useCallback(
    (message: string) => {
      setUploading({
        status: "error",
        payload: message,
      });
    },
    [setUploading]
  );

  const checkFields = useCallback(
    (inputFields: any) => {
      for (const f of fields) {
        // @ts-ignore
        if (!inputFields[f.field]) {
          return false;
        }
      }

      if (!validateEmail(inputFields.email)) {
        throw new Error("The given email format is wrong!");
      }
      return true;
    },
    [validateEmail]
  );

  const handleSend = async () => {
    setUploading({ status: "loading", payload: "" });
    try {
      const file = fileList[0];
      const extensionIndex = file?.name?.split(".").length - 1;
      if (
        file &&
        file.name &&
        file.name.split(".")[extensionIndex] === "zip" &&
        checkFields(inputFields)
      ) {
        const data = {
          ...inputFields,
          fileName: file.name,
          fileSizeBytes: file.size,
          fileFormat: file.type,
        };
        const urlResponse = await getUploadLink(data);
        if (urlResponse.data?.signedUrl) {
          console.log("signed url", urlResponse.data.signedUrl);
          console.log("file", file);
          console.log("file.type", file.type);
          const uploadResponse = await uploadZipFile(
            urlResponse.data.signedUrl,
            file,
            file.type
          );
          if (uploadResponse.status === 200) {
            setUploading({
              status: "success",
              payload: "File uploaded successfully.",
            });
          } else {
            showError("Something went wrong while updating data.");
          }
        }
      } else {
        if (!file || !file.name || file.name.split(".")[1] !== "zip") {
          showError("Please select a file with correct type. (.zip)");
        } else {
          showError("Please fill all required fields.");
        }
      }
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.message) {
        showError(
          err.response.data.message +
            " - " +
            err.response.data.details?.join(",")
        );
      } else {
        showError(err.message);
      }
    }
  };
  return (
    <Card style={{height:"100vh"}} className="shadow-2xl w-full h-full explanation-texts">
      <div>
        <h2 className="text-center">Upload Data</h2>
        <Row className="justify-center">
          <Col md={24} lg={12}>
            <p>
              You can submit your RNA-seq data to T-Drug on this page. After
              uploading, we will review your data in maximum of 3 working days
              and once it is approved, we’ll send you confirmation.
            </p>
            <br />
            <p>
              Please follow the template from
              <a
                href="https://github.com/sysmedicine/Drugrepositioning_webtool/tree/main/Upload_template"
                target="_blank"
                rel="noopener noreferrer"
              >
                this GitHub link.
              </a>
              Please note that the count profiles and meta data are necessary.
            </p>
          </Col>
          <Col xs={24}></Col>
          <Col md={24} lg={12}>
            {fields.map((field, i) => (
              <Row key={field.field} className="align-center margin-top-10p">
                {i === 5 && (
                  <Col xs={24}>
                    <h3 className="margin-top-20p">Contact Information</h3>
                  </Col>
                )}
                <Col xs={13} md={8} lg={6}>
                  <>
                    <b>
                      <span style={{ color: "red" }}>* </span> {field.label}:
                    </b>
                    {field.isModal && (
                      <>
                        <QuestionCircleOutlined
                          className="ml-2"
                          onClick={() => {
                            setModalsVisibility((prev) => {
                              const newModalsVisibility = [...prev];
                              newModalsVisibility[i] = true;
                              return newModalsVisibility;
                            });
                          }}
                        />

                        <HelpModal
                          visible={modalsVisibility[i]}
                          onClose={() => {
                            setModalsVisibility((prev) => {
                              const newModalsVisibility = [...prev];
                              newModalsVisibility[i] = false;
                              return newModalsVisibility;
                            });
                          }}
                          title={field.label}
                        >
                          <p>{field.modalText}</p>
                        </HelpModal>
                      </>
                    )}
                  </>
                </Col>
                <Col className="margin-left-1r" xs={16} md={14} lg={12}>
                  {field.type === "input" ? (
                    <Input
                      onChange={(val) => {
                        const { currentTarget } = val;
                        setInputFields((inputs: any) => ({
                          ...inputs,
                          [field.field]: currentTarget.value,
                        }));
                      }}
                    />
                  ) : (
                    field.type === "textarea" && (
                      <TextArea
                        rows={4}
                        onChange={(val) => {
                          const { currentTarget } = val;
                          setInputFields((inputs: any) => ({
                            ...inputs,
                            [field.field]: currentTarget.value,
                          }));
                        }}
                      />
                    )
                  )}
                </Col>
              </Row>
            ))}

            <Row className="align-center margin-top-10p">
              <Col xs={13} md={8} lg={6}>
                <>
                  <b>
                    <span style={{ color: "red" }}>* </span>Upload (.zip file):
                  </b>
                </>
              </Col>
              <Col className="margin-left-1r" xs={16} md={14} lg={12}>
                <Upload
                  fileList={fileList}
                  accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                  onRemove={(file: any) => {
                    setFileList((fileList: any) => {
                      return fileList.filter((f: any) => f !== file);
                    });
                  }}
                  beforeUpload={(file: any) => {
                    setFileList([file]);
                    return false;
                  }}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Col>
            </Row>

            <Row className="align-center margin-top-10p">
              <Col xs={13} md={8} lg={6}></Col>
              <Col className="margin-left-1r" xs={16} md={14} lg={12}>
                * The maximum allowed file size is <b>250MB</b>. If your data is
                greater than 250MB, please contact with{" "}
                <i>xiangyu.li@scilifelab.se</i> or <i>adilm@scilifelab.se.</i>
              </Col>
            </Row>

            <Row className="align-center margin-top-20p">
              <Col xs={13} md={8} lg={6}></Col>
              <Col
                className="justify-end margin-left-1r"
                xs={16}
                md={14}
                lg={12}
              >
                <Button
                  type="primary"
                  onClick={handleSend}
                  loading={uploading.status === "loading"}
                >
                  Send
                </Button>
              </Col>
            </Row>

            {(uploading.status === "success" ||
              uploading.status === "error") && (
              <Alert
                className="align-center margin-top-10p"
                message={uploading.status === "success" ? "Success" : "Error"}
                description={uploading.payload}
                type={uploading.status}
              />
            )}
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default UploadPage;
