import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";
const CiteAndDataPage = () => {
  const [isDataCopied1, setIsDataCopied1] = React.useState(false);
  const [isDataCopied2, setIsDataCopied2] = React.useState(false);

  return (
    <div className="h-screen overflow-auto bg-white">
      <div className="max-w-5xl px-10 mx-auto my-10">
        <div className="pb-8 text-4xl font-thin">
          <span>Data Availability and Citation</span>
        </div>
        <div className="font-light">
          The level 5 gene signature profiles of 31 human cell lines perturbed
          by gene knockdown, knockout, overexpression and compound treatment can
          be downloaded from CMap website (
          <a href="https://clue.io/data/CMap2020#LINCS2020">
            https://clue.io/data/CMap2020#LINCS2020
          </a>
          ). The meta data and similarity matrices can be downloaded below.
          <br />
          <br />
          <ul>
            <li>
              {" "}
              <a
                href="https://drive.google.com/file/d/13w5s0k5hr5xa7bg1Ho6dmEixORlaSMnx/view?usp=sharing"
                style={{ color: "rgba(59, 130, 246)" }}
              >
                sh.tar.gz:
              </a>{" "}
              Similarity matrices between shRNA gene knockdown perturbation (sh)
              and compound perturbation (cp) for all the associated cell lines.
            </li>
            <li>
              <a
                href="https://drive.google.com/file/d/1rnd-vsGWfhn8bk5nc6ckgGSm0lNPXeB7/view?usp=sharing"
                style={{ color: "rgba(59, 130, 246)" }}
              >
                xpr.tar.gz:
              </a>{" "}
              Similarity matrices between CRISPR-CAS9 gene knockout perturbation
              (xpr) and compound perturbation (cp) for all the associated cell
              lines.
            </li>
            <li>
              {" "}
              <a
                href="https://drive.google.com/file/d/1eRF-ujNAjPapTjZZU7Zw0Jyuek2PaEaR/view?usp=sharing"
                style={{ color: "rgba(59, 130, 246)" }}
              >
                oe.tar.gz:
              </a>{" "}
              Similarity matrices between gene overexpression perturbation (oe)
              and compound (cp) perturbation for all the associated cell lines.{" "}
            </li>
            <li>
              {" "}
              <a
                href="https://drive.google.com/file/d/1dEuYh2tCu_ApnPgKJlGo5TXYg01hw1NX/view?usp=sharing"
                style={{ color: "rgba(59, 130, 246)" }}
              >
                Target_info.txt:
              </a>{" "}
              The information of all available target genes.{" "}
            </li>
            <li>
              {" "}
              <a
                href="https://drive.google.com/file/d/10_YYDconKDXx4n4Y4AyfgpjNHVt0mySQ/view?usp=sharing"
                style={{ color: "rgba(59, 130, 246)" }}
              >
                Drug_info.txt:
              </a>{" "}
              The information of all available drugs.{" "}
            </li>
            <li>
              {" "}
              <a
                href="https://docs.google.com/spreadsheets/d/1-fn0chgAUbknlpFl3G9yJEnSc7PbvDDG/edit?usp=sharing&ouid=111323456859441760600&rtpof=true&sd=true"
                style={{ color: "rgba(59, 130, 246)" }}
              >
                Cell_line_info.xlsx:
              </a>{" "}
              The information of all available cell lines.{" "}
            </li>
          </ul>
          <br />
          If you use any result from T-Drug for your research or publication,
          please cite the paper of our original drug repositioning approach as
          follow:
          <br />
          <br />
          <div
            style={{
              backgroundColor: "#aaa",
              paddingInline: "1rem",
              paddingBlock: "2rem",
              display: "flex",
            }}
          >
            <div style={{ width: "100%" }}>
              Xiangyu Li,  Koeun Shong ,  Woonghee Kim ,  Meng Yuan , Hong
              Yang, Yusuke Sato, Haruki Kume, Seishi Ogawa, Hasan Turkez, Saeed
              Shoaie, Jan Boren, Jens Nielsen, Mathias Uhlen, Cheng Zhang,  Adil
              Mardinoglu . Prediction of drug candidates for clear cell renal
              cell carcinoma using a systems biology-based drug repositioning
              approach. EBioMedicine. 2022 Apr;78:103963. doi:
              10.1016/j.ebiom.2022.103963. Epub 2022 Mar 25. (PMID: 35339898)
            </div>
            <Tooltip placement="rightTop" title="Copy To Clipboard">
              <button
                style={{ marginRight: "2rem" }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    "Xiangyu Li,  Koeun Shong ,  Woonghee Kim ,  Meng Yuan , Hong Yang, Yusuke Sato, Haruki Kume, Seishi Ogawa, Hasan Turkez, Saeed Shoaie, Jan Boren, Jens Nielsen, Mathias Uhlen, Cheng Zhang,  Adil Mardinoglu . Prediction of drug candidates for clear cell renal cell carcinoma using a systems biology-based drug repositioning approach. EBioMedicine. 2022 Apr;78:103963. doi: 10.1016/j.ebiom.2022.103963. Epub 2022 Mar 25. (PMID: 35339898)"
                  );
                  setIsDataCopied1(true);
                  setIsDataCopied2(false);
                }}
              >
                {!isDataCopied1 ? (
                  <CopyOutlined style={{ width: "100%" }} />
                ) : (
                  <CheckOutlined style={{ color: "green", width: "100%" }} />
                )}
              </button>
            </Tooltip>
          </div>
          <div
            style={{
              backgroundColor: "#aaa",
              paddingInline: "1rem",
              paddingBlock: "2rem",
              display: "flex",
              marginTop: "1rem",
            }}
          >
            <div style={{ width: "100%" }}>
              Cheng Zhang, Mengnan Shi, Woonghee Kim, Muhammad Arif, Martina
              Klevstig, Xiangyu Li, Hong Yang, Cemil Bayram, Ismail Bolat, Özlem
              Özdemir Tozlu, Ahmet Hacımuftuoglu, Serkan Yıldırım, Jihad
              Sebhaoui, Shazia Iqbal, Yongjun Wei, Xiaojing Shi, Jens Nielsen,
              Hasan Turkez, Mathias Uhlen, Jan Boren, Adil Mardinoglu. Discovery
              of therapeutic agents targeting PKLR for NAFLD using drug
              repositioning. EBioMedicine. 2022 Sep;83:104214. doi:
              10.1016/j.ebiom.2022.104214. Epub 2022 Aug 18. (PMID: 35988463)
            </div>
            <Tooltip placement="rightTop" title="Copy To Clipboard">
              <button
                style={{ marginRight: "2rem" }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    "Cheng Zhang, Mengnan Shi, Woonghee Kim, Muhammad Arif, Martina Klevstig, Xiangyu Li, Hong Yang, Cemil Bayram, Ismail Bolat, Özlem Özdemir Tozlu, Ahmet Hacımuftuoglu, Serkan Yıldırım, Jihad Sebhaoui, Shazia Iqbal, Yongjun Wei, Xiaojing Shi, Jens Nielsen, Hasan Turkez, Mathias Uhlen, Jan Boren, Adil Mardinoglu. Discovery of therapeutic agents targeting PKLR for NAFLD using drug repositioning. EBioMedicine. 2022 Sep;83:104214. doi: 10.1016/j.ebiom.2022.104214. Epub 2022 Aug 18. (PMID: 35988463)"
                  );
                  setIsDataCopied2(true);
                  setIsDataCopied1(false);
                }}
              >
                {!isDataCopied2 ? (
                  <CopyOutlined style={{ width: "100%" }} />
                ) : (
                  <CheckOutlined style={{ color: "green", width: "100%" }} />
                )}
              </button>
            </Tooltip>
          </div>
          <br /> <br />
          <strong>Contact:</strong> please contact{" "}
          <a href="mailto:adilm@scilifelab.se">adilm@scilifelab.se</a> or {""}
          <a href="mailto:xiangyu.li@scilifelab.se">
            xiangyu.li@scilifelab.se
          </a>{" "}
          {"  "}
          if you have any question.
        </div>
      </div>
    </div>
  );
};

export default CiteAndDataPage;
