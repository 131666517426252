import { QueryRequest, QueryResponse } from "../utils/types";

const queryDefaultState = {
  queryResponse: undefined,
  doQueryOp: { status: "init", payload: "" },
  requestedQueryDetails: undefined,
  currentQueryDetails: {
    geneOrDrug: "",
    pertType: null,
    isActivator: null,
    cellLine: null,
    absoluteSimilarity: 0.4,
    nodeLimit: 10,
  },
  moveQueryAreaLeft: false,
  showTutorial: false,
};

const reducer = (
  state = queryDefaultState,
  action: {
    type: string;
    status?: string;
    payload?: any;
    currentQueryDetails: QueryRequest;
    lastQueryString: string;
    query: QueryRequest;
    showTutorial: boolean;
    queryResponse?: QueryResponse;
  }
) => {
  switch (action.type) {
    case "query/DO_QUERY":
      return {
        ...state,
        queryResponse: action.queryResponse,
        doQueryOp: {
          status: action.status,
          payload: action.payload,
        },
        requestedQueryDetails: action.query,
        moveQueryAreaLeft: true,
      };
    case "query/UPDATE_CURRENT_QUERY_DETAILS":
      return {
        ...state,
        currentQueryDetails: action.currentQueryDetails,
      };
    case "query/UPDATE_LAST_QUERY_STRING":
      return {
        ...state,
        lastQueryString: action.lastQueryString,
      };
    case "query/SET_SHOW_TUTORIAL":
      return {
        ...state,
        showTutorial: action.showTutorial,
      };
    case "query/RESET_QUERY_RESPONSE":
      return {
        ...state,
        queryResponse: { nodes: [], links: [] },
        doQueryOp: { status: "init", payload: "" },
        requestedQueryDetails: undefined,
        moveQueryAreaLeft: false,
      };
    default:
      return state;
  }
};

export default reducer;
