import create from "zustand";

export const useStore = create((set) => ({
  nodes: [],
  links: [],
  viewType: "Edge Table",
  loading: false,
  cellLineData: [],
  zoomToFitToggler: false,
  formFields: {
    geneOrDrug: "",
    pertType: null,
    isActivator: null,
    cellLine: null,
    absoluteSimilarity: 0.4,
    nodeLimit: 10,
  },
  areLinksVisible: true,
  zoomToFit: (zoomToFitToggler: boolean) =>
    set((state) => ({ ...state, zoomToFitToggler })),
  patchFormFields: (newFormFields: any) => {
    set((state: any) => ({
      ...state,
      formFields: { ...state.formFields, ...newFormFields },
    }));
  },
  setAreLinksVisible: (areLinksVisible: boolean) => {
    set((state: any) => ({
      ...state,
      areLinksVisible,
    }));
  },
  setNodes: (nodes: any[]) => set((state) => ({ nodes })),
  setLinks: (links: any[]) => set((state) => ({ links })),
  setCellLineData: (cellLineData: any[]) => set((state) => ({ cellLineData })),
  setViewType: (viewType: string) => set((state) => ({ viewType })),
  setLoading: (loading: boolean) => set((state) => ({ loading })),
}));
