// @ts-nocheck
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import ForceGraph2D from "react-force-graph-2d";
import { connect } from "react-redux";
import { addLink, addNode } from "../../actions/graph-modals";
import {
  Dispatch,
  func,
  Node,
  QueryResponse,
  store,
  LinkWithNodes,
  ColoredField,
} from "../../utils/types";
import GraphInformationModal from "./GraphInformationModal";
import * as d3 from "d3";
import { useStore } from "../../reducers/zustand/store";

type Props = {
  queryResponse: QueryResponse;
  addNodeAction: func;
  addLinkAction: func;
  size?: any;
  zoomToFitToggler?: boolean;
  coloredField?: ColoredField;
  hoveredInfoType: string;
};

const DrawGraph = ({
  queryResponse,
  addNodeAction,
  addLinkAction,
  size,
}: Props) => {
  const { areLinksVisible, zoomToFitToggler, formFields } = useStore(
    (state: any) => ({
      links: state.links,
      nodes: state.nodes,
      setNodes: state.setNodes,
      setLinks: state.setLinks,
      viewType: state.viewType,
      areLinksVisible: state.areLinksVisible,
      setAreLinksVisible: state.setAreLinksVisible,
      zoomToFitToggler: state.zoomToFitToggler,
      formFields: state.formFields,
    })
  );
  const isCellLineAll = formFields.cellLine === "All";
  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(null);
  const fgRef = useRef();
  const nodeClickHandler = useCallback(
    (node: any) => {
      addNodeAction(node);
    },
    [addNodeAction]
  );

  const linkClickHandler = useCallback(
    (link: any) => {
      addLinkAction(link);
    },
    [addLinkAction]
  );

  useEffect(() => {
    fgRef.current?.d3Force(
      "x",
      d3
        .forceX(function () {
          return 0;
        })
        .strength(0.2)
    );

    fgRef.current?.d3Force(
      "y",
      d3
        .forceY(function () {
          return 0;
        })
        .strength(0.2)
    );

    //fgRef.current?.d3Force('collide', d3.forceCollide(14).iterations(25));
    fgRef.current?.d3Force(
      "manyBody",
      d3.forceManyBody().strength([-300]).theta([0])
    );

    fgRef.current?.d3Force("link").distance((link) => 120);
    //eslint-disable-next-line
  }, []);

  const zoomToFit = useCallback(() => {
    const padding = 0.1 * Math.min(size.width, size.height);
    if (padding > 0) {
      fgRef.current?.zoomToFit(1000, padding);
    }
  }, [size]);

  useEffect(() => {
    zoomToFit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomToFitToggler]);

  useEffect(() => {
    setTimeout(() => {
      zoomToFit();
    }, 1000);
  }, [queryResponse, size, zoomToFit]);

  const data = useMemo(() => {
    const nodes = isCellLineAll
      ? [
          ...queryResponse.nodes.slice(
            0,
            Math.min(49, queryResponse.nodes.length - 1)
          ),
          queryResponse.nodes[queryResponse.nodes.length - 1],
        ].map((node: Node) => {
          return {
            ...node,
            id: +node.id,
            label: node.ID,
            vx: 0,
            vy: 0,
          };
        })
      : queryResponse.nodes
          .slice(0, Math.min(50, queryResponse.nodes.length))
          .map((node: Node) => {
            return {
              ...node,
              id: +node.id,
              label: node.search_index,
              vx: 0,
              vy: 0,
            };
          });
    const links = queryResponse.links
      .map((link: LinkWithNodes) => {
        return {
          ...link,
          source: +link.start,
          target: +link.end,
        };
      })
      .filter((link: any) => {
        return (
          nodes.find((node: Node) => Number(node.id) === Number(link.end)) &&
          nodes.find((node: Node) => Number(node.id) === Number(link.start))
        );
      });

    return { nodes, links };
  }, [queryResponse, isCellLineAll]);

  const updateHighlight = () => {
    setHighlightNodes(highlightNodes);
    setHighlightLinks(highlightLinks);
  };

  const handleNodeHover = () => {
    /*
    highlightNodes.clear();
    highlightLinks.clear();
    if (node) {
      highlightNodes.add(node);
      node.neighbors.forEach((neighbor) => highlightNodes.add(neighbor));
      node.links.forEach((link) => highlightLinks.add(link));
    }

    setHoverNode(node || null);
    updateHighlight();
    */
  };

  const handleLinkHover = (link) => {
    highlightNodes.clear();
    highlightLinks.clear();

    if (link) {
      highlightLinks.add(link);
      highlightNodes.add(link.source);
      highlightNodes.add(link.target);
    }

    updateHighlight();
  };

  return (
    <>
      <div className="relative flex flex-1 w-full h-full">
        {data?.nodes.length > 0 ? (
          <>
            <ForceGraph2D
              graphData={data}
              width={size.width}
              height={size.height}
              ref={fgRef}
              nodeLabel={(node) => {
                return node.search_index;
              }}
              linkLabel={(link: LinkWithNodes) => {
                return `<div class="p-4 flex flex-col space-y-2"><p>${
                  link.source.label
                } --- ${link.target.label}</p>
                        ${
                          isCellLineAll
                            ? `
                        <p>Confidence Score: ${link.medianCorrelation.toFixed(
                          4
                        )} </p>
                        <p>Effect Type: ${link.effect_type} </p>
                        <p>Hit Rate (%): ${(link.hit_rate * 100).toFixed(
                          2
                        )} </p>
                        <p>Number Of Hits: ${link.numberOfHits} </p>
                        <p>Cell Line: ${link.cell_lines.join("/ ")} </p></div>`
                            : `
                        <p>Confidence Score: ${link.correlation.toFixed(4)} </p>
                        <p>Effect Type: ${link.effect_type} </p>
                        <p>Cell Line: ${link.cell_line} </p></div>`
                        }`;
              }}
              onNodeClick={nodeClickHandler}
              onLinkClick={linkClickHandler}
              onNodeDragEnd={(node) => {
                node.fx = node.x;
                node.fy = node.y;
              }}
              nodeCanvasObject={(node: any | any, ctx: any) => {
                const { id, x, y, search_index } = node;
                const targetNode = queryResponse.nodes.find(
                  (node) => +node.id === +id
                );

                ctx.fillStyle = targetNode.isGene
                  ? targetNode?.isGene === "true"
                    ? "#0369a1"
                    : "#3ded97"
                  : "purple";

                ctx.beginPath();
                ctx.arc(x, y, 2.5, 0, 2 * Math.PI, false);

                ctx.strokeStyle = "rgba(249, 250, 251, 1)";
                ctx.lineWidth = 1;
                ctx.stroke();
                ctx.fill();

                ctx.beginPath();
                ctx.arc(x, y, 5, 0, 2 * Math.PI, false);

                ctx.strokeStyle = "rgba(249, 250, 251, 1)";
                ctx.lineWidth = 1.5;
                ctx.stroke();
                ctx.fill();

                ctx.font = "bold 5px Helvetica";
                ctx.textAlign = "center";
                ctx.fillStyle = "#334155";
                ctx.fillText(search_index, x, y - 6.5);
              }}
              linkWidth={1.5}
              enableNodeDrag={true}
              onNodeHover={handleNodeHover}
              onLinkHover={handleLinkHover}
              linkVisibility={areLinksVisible}
            />
            <GraphInformationModal
              nodes={queryResponse.nodes}
              links={queryResponse.links}
            />
          </>
        ) : (
          <>
            <div className="flex items-center justify-center w-full py-20 bg-gray-50">
              Could not found any node!
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: store) => ({
  coloredField: state.graphModals.coloredField,
  hoveredInfoType: state.graphModals.hoveredInfoType,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNodeAction: (node: Node) => dispatch(addNode(node)),
  addLinkAction: (link: LinkWithNodes) => dispatch(addLink(link)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawGraph);
