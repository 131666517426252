import { Dispatch, QueryRequest, QueryResponse, store } from "../utils/types";
import { query as queryService } from "../services/query";
import { resetModals, setColoredField } from "./graph-modals";
import {
  includeNodesToLinks,
  includeNodesInfo,
  includeIndexClone,
  getColorsForField,
} from "../utils/helpers";
import * as H from "history";
import { DEFAULT_COLOR_FIELD } from "../reducers/graph-modals";
import throttle from "lodash.throttle";

export const doQueryOperation = (
  query: QueryRequest,
  status: string,
  payload?: any,
  queryResponse?: QueryResponse
) => ({
  query,
  type: "query/DO_QUERY",
  status,
  payload,
  queryResponse,
});

const makeQueryRequest = throttle(
  (dispatch: Dispatch, getState: any, queryRequest: QueryRequest) => {
    dispatch(resetModals());
    dispatch(doQueryOperation(queryRequest, "loading", undefined));
    queryService(queryRequest).then(
      (res: any) => {
        if (!queryRequest.geneOrDrug) {
          includeNodesToLinks(res.data.data);
        }

        includeNodesInfo(res.data.data);

        includeIndexClone(res.data.data);

        const coloredField = (getState() as store).graphModals.coloredField;

        const key = coloredField ? coloredField.key : DEFAULT_COLOR_FIELD;
        const colors = getColorsForField(res.data.data?.nodesInfo, key);

        dispatch(setColoredField({ key, colors }));

        dispatch(
          doQueryOperation(queryRequest, "success", undefined, res.data.data)
        );
      },
      (err) => {
        dispatch(doQueryOperation(queryRequest, "fail", "" + err));
      }
    );
    window.gtag("event", "query", {
      event_category: "query-request",
      full_query: JSON.stringify(queryRequest),
      ...queryRequest,
    });
  },
  1000,
  { trailing: false }
);

export const makeQuery = (queryRequest: QueryRequest) => {
  return (dispatch: Dispatch, getState: any) => {
    makeQueryRequest(dispatch, getState, queryRequest);
  };
};

export const updateCurrentQueryDetiails = (
  currentQueryDetails: QueryRequest
) => ({
  type: "query/UPDATE_CURRENT_QUERY_DETAILS",
  currentQueryDetails,
});

export const updateLastQueryString = (lastQueryString: string) => ({
  type: "query/UPDATE_LAST_QUERY_STRING",
  lastQueryString,
});

export const patchCurrentQueryDetiails = (updatedFields: any) => {
  return (dispatch: Dispatch, getState: any) => {
    const currQueryDetails = (getState() as store).query.currentQueryDetails;
    dispatch(
      updateCurrentQueryDetiails({ ...currQueryDetails, ...updatedFields })
    );
  };
};

export const setShowTutorial = (showTutorial: boolean) => ({
  type: "query/SET_SHOW_TUTORIAL",
  showTutorial,
});

export const queryWithCurrentQueryDetails = (
  history: H.History<unknown>,
  force = false
) => {
  return (dispatch: Dispatch, getState: any) => {
    const currQueryDetails = (getState() as store).query.currentQueryDetails;
    // @ts-ignore
    // delete currQueryDetails.clustering; // TODO will be deleted when clustering active
    const queryObj: any = {
      ...currQueryDetails,
    };
    let queryParams = new URLSearchParams(queryObj).toString();

    const lastQueryString = (getState() as store).query.lastQueryString;
    if (!force && lastQueryString === queryParams) {
      return;
    }

    dispatch(updateLastQueryString(queryParams));
    //history.push("/?" + queryParams);
  };
};

export const resetQueryResponse = () => ({
  type: "query/RESET_QUERY_RESPONSE",
});
