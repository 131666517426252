import { Modal } from "antd";

const HelpModal = ({ visible, children, onClose, title }: any) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onClose}
      cancelText={null}
      footer={null}
    >
      {children}
    </Modal>
  );
};

export default HelpModal;
