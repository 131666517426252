import { MutableRefObject, useRef, useState } from "react";
// @ts-ignore
import { useJsonToCsv, Js } from "react-json-csv";
import { Node } from "../../utils/types";
import { Button } from "antd";
import { connect } from "react-redux";
import { cropNodeForExport, getHexColorFromHsl } from "../../utils/helpers";
import { FileExcelOutlined } from "@ant-design/icons";
import { useStore } from "../../reducers/zustand/store";
import { determineDir } from "./TableView";
type Props = {
  graphWrapperRef: MutableRefObject<any | undefined>;
  visualize: boolean;
  coloredField: any;
};
const Export = ({ graphWrapperRef, visualize, coloredField }: Props) => {
  const [loadings, setLoadings] = useState<any>({});
  const [legendVisible, setLegendVisible] = useState(false);
  const legendWrapperRef = useRef<any>(null);
  const { saveAsCsv } = useJsonToCsv();
  const { nodes, links, formFields } = useStore((state: any) => ({
    nodes: state.nodes,
    links: state.links,
    formFields: state.formFields,
  }));

  const handleExportNodesCsv = () => {
    setLoadings((loads: any) => ({ ...loads, nodesCsv: true }));
    let data = links.map((link: any) => {
      return {
        start:
          nodes.find((node: Node) => Number(node.id) === Number(link.start))
            ?.search_index ?? "NONE",
        end:
          nodes.find((node: Node) => Number(node.id) === Number(link.end))
            ?.search_index ?? "NONE",
        correlation: link.correlation?.toFixed(4) ?? 0,
        effect_type:
          link.effect_type === "inhibitor" ? "Inhibition" : "Activation",
        cell_line: link.cell_line,
        pertType: formFields.pertType,
        dir: determineDir(formFields),
        total_lines: link.total_lines,
        numberOfHits: link.numberOfHits,
        hit_rate: (link.hit_rate * 100)?.toFixed(2),
        cell_lines: link.cell_lines?.join("/"),
        medianCorrelation: link.medianCorrelation?.toFixed(4) ?? 0,
      };
    });

    const isCellLineAll = formFields.cellLine === "All";

    let fields = {
      ...(isCellLineAll
        ? {
            start: "Drug",
            end: "Target",
            effect_type: "Effect Type",
            total_lines: "Num Cells",
            numberOfHits: "Num Hit Cells",
            hit_rate: "Hit Rate (%)",
            cell_lines: "Hit Cells",
            medianCorrelation: "Confidence Score",
          }
        : {
            start: "Source",
            end: "Target",
            effect_type: "Effect Type",
            pertType: "Pert Type",
            dir: "Dir",
            cell_line: "Cell Line",
            correlation: "Confidence Score",
          }),
    };

    saveAsCsv({
      data,
      fields,
      fileformat: "csv",
      filename: "Edge Table",
      separator: ";",
    });
    setLoadings((loads: any) => ({ ...loads, nodesCsv: false }));
  };

  return (
    <>
      <div className="flex flex-col">
        <Button
          className="px-6 py-5 shadow-lg hover:shadow-none"
          icon={<FileExcelOutlined />}
          loading={loadings.nodesCsv}
          onClick={handleExportNodesCsv}
        >
          Export
        </Button>
      </div>
    </>
  );
};
export default Export;
