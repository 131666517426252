import React from "react";
import { Layout } from "antd";
import AppRouter from "./AppRouter";
import Navbar from "./layouts/Navbar";

const App = () => {
  return (
    <>
      <div className="flex flex-col h-full max-h-screen min-h-screen">
        <Navbar />
        <Layout className="flex flex-col flex-1">
          <AppRouter />
        </Layout>
      </div>
    </>
  );
};

export default App;
