import { connect } from "react-redux";
import { updateCurrentQueryDetiails } from "../../../actions/query";
import {
  Dispatch,
  EffectType,
  QueryRequest,
  store,
} from "../../../utils/types";
import { Button, Form, Modal, Select } from "antd";
import { useStore } from "../../../reducers/zustand/store";
import { useState } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
const { Option } = Select;

const EffectTypeSelect = ({ values, setValues }: any) => {
  const { formFields, patchFormFields } = useStore((state: any) => ({
    formFields: state.formFields,
    patchFormFields: state.patchFormFields,
  }));

  const onChange = (tags: string[]) => {
    const isActivator = tags.includes("Activation");
    patchFormFields({ isActivator });
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const value =
    formFields.isActivator === null
      ? []
      : formFields.isActivator
      ? ["Activation"]
      : ["Inhibition"];

  return (
    <div id="search-tags" className="w-full mt-5">
      <Form.Item
        labelCol={{ span: 24 }}
        label={
          <>
            <div>Effect Type</div>
            <Button
              onClick={showModal}
              style={{
                border: "none",
                width: "fit-content",
                display: "inline",
              }}
            >
              <QuestionCircleOutlined />
            </Button>
            <Modal
              title="Effect Type"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
            >
              <p>
                <strong>Inhibition:</strong> Predict the inhibitors of the given
                target gene or the target genes that can be inhibited by the
                given drug.
                <br />
                <br />
                <strong>Activation:</strong> Predict the activators of the given
                target gene or the target genes that can be activated by the
                given drug.
              </p>
            </Modal>
          </>
        }
        required
      >
        <Select
          value={value}
          onChange={onChange}
          placeholder="activation/inhibition"
          className="w-full rounded-select"
        >
          {Object.values(EffectType).map((o: string, i: any) => (
            <Option key={i} value={o}>
              {o}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

const mapStateToProps = (state: store) => ({
  values: state.query.currentQueryDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setValues: (currentQueryDetails: QueryRequest) =>
    dispatch(updateCurrentQueryDetiails(currentQueryDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EffectTypeSelect);
