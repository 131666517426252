declare global {
  interface Window {
    gtag: any;
  }
}

export type ColoredField = {
  key: string;
  colors: { [propertyName: string]: string[] };
};

export type store = {
  dataTypes: {
    allDataTypes: string[];
    catStructure: CategoryStructure;
    firstInitData: string;
  };
  query: {
    doQueryOp: { status: string; payload: any };
    queryResponse: QueryResponse;
    requestedQueryDetails: QueryRequest;
    currentQueryDetails: QueryRequest;
    lastQueryString: string;
    moveQueryAreaLeft: boolean;
    showTutorial: boolean;
  };
  graphModals: {
    nodes: Node[];
    links: LinkWithNodes[];
    areLinksVisible: boolean;
    zoomToFitToggler: boolean;
    viewType: string;
    backgroundType: string;
    coloredField?: ColoredField;
    hoveredInfoType: string;
  };
};
export type Dispatch = any;
export type func = any;
export type FixType = any;

export type DataTypes = {
  locations: string[];
  categoryStructure: CategoryStructure;
};

export type CategoryStructure = {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        id: string;
        locations: string[];
      };
    };
  };
};

export enum QueryRequestType {
  GENE = "gene",
  DRUG = "drug",
}

export enum PertType {
  SH = "SH",
  OE = "OE",
  XPR = "XPR",
}

export enum EffectType {
  ACTIVATOR = "Activation",
  INHIBITOR = "Inhibition",
}

export type QueryRequest = {
  geneOrDrug: string;
  pertType: string | null;
  isActivator: boolean | null;
  cellLine: string | null;
  absoluteSimilarity: number;
  nodeLimit: number;
};

export type NodesInfo = {
  [propertyName: string]: any[];
};

export type QueryResponse = {
  nodes: Node[];
  links: Link[] | LinkWithNodes[];
  nodesInfo: NodesInfo;
};

export type Node = {
  drug_pert_id: string;
  drug_cmap_name: string;
  ID: string;
  id: number;
  gene_type: string;
  isGene: boolean;
  label_special_pert: string;
  search_index: string;
  link: string;
};

export type Link = {
  start: number;
  end: number;
} & LinkSpecs;

export type LinkWithNodes = {
  start: number;
  end: number;
} & LinkSpecs;

export type LinkSpecs = {
  correlation: number;
  effect_type: number;
  cell_line: number;
};
