import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "antd/dist/antd.css";
import "./index.css";

import App from "./components/App";
import configureStore from "./config/store";

const store = configureStore();

// Prevent deprecated in strictmode console warnings because of ant design's unupgraded components. Hopefully they will upgrade then this will be removed
(() => {
  const oldLogError = console.error;
  console.error = function (...args: any) {
    if (
      typeof args[0] !== "string" ||
      (args[0].indexOf("is deprecated in StrictMode") === -1 &&
        args[0].indexOf("UNSAFE_componentWillReceiveProps in strict mode") ===
          -1 &&
        args[0].indexOf("UNSAFE_componentWillMount in strict mode") === -1)
    ) {
      oldLogError.apply(console, args);
    }
  };
})();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
