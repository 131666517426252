import { Layout, Spin } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setInitData } from "../../actions/data-types";
import { resetQueryResponse } from "../../actions/query";
import { Dispatch, func, store } from "../../utils/types";
import QueryArea from "../common-components/QueryArea";
import TourComponent from "../common-components/TourComponent";
import Main from "../content/main";
import MainPage from "./MainPage";

const { Content } = Layout;

type Props = {
  firstInitDataTypes: string;
  setInitDataAction(): func;
  resetQueryResponseAction(): func;
};

const QueryPage = ({
  firstInitDataTypes,
  setInitDataAction,
  resetQueryResponseAction,
}: Props) => {
  useEffect(() => {
    setInitDataAction();
    return () => {
      resetQueryResponseAction();
    };
  }, [setInitDataAction, resetQueryResponseAction]);
  return (
    <div className="flex flex-1 h-full">
      <div className="hidden w-2/3 overflow-auto md:block">
        <Main />
      </div>
      <div className="w-full overflow-auto bg-white md:w-1/3">
        <div className="w-full h-full max-w-lg px-8 mx-auto">
          <div className="text-5xl font-thin pt-14 mb-11 lg:text-6xl ">
            <span>Search</span>
          </div>
          <QueryArea />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: store) => ({
  firstInitDataTypes: state.dataTypes.firstInitData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setInitDataAction: () => dispatch(setInitData()),
  resetQueryResponseAction: () => dispatch(resetQueryResponse()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QueryPage);
