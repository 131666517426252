import { ColoredField, LinkWithNodes, Node } from "../utils/types";

export const viewTypes = ["Graph", "Edge Table"];

export const backgroundTypes = ["None", "Dots 1", "Dots 2", "Grid 1", "Grid 2"];

export const DEFAULT_COLOR_FIELD = "Genus";

const layoutDefaultState: {
  nodes: Node[];
  links: LinkWithNodes[];
  areLinksVisible: boolean;
  zoomToFitToggler: boolean;
  viewType: string;
  backgroundType: string;
  coloredField?: ColoredField;
} = {
  nodes: [],
  links: [],
  areLinksVisible: false,
  zoomToFitToggler: false,
  viewType: viewTypes[0],
  backgroundType: backgroundTypes[0],
  coloredField: undefined,
};

const modal = (state = layoutDefaultState, action: any) => {
  switch (action.type) {
    case "graph-modals/ADD_NODE":
      return {
        ...state,
        nodes: state.nodes.some((node) => node.id === action.node.id)
          ? state.nodes
          : [...state.nodes, action.node],
      };
    case "graph-modals/ADD_LINK":
      return {
        ...state,
        links: state.links.some(
          (link: any) =>
            link.source.id === action.link.source.id &&
            link.target.id === action.link.target.id
        )
          ? state.links
          : [...state.links, action.link],
      };
    case "graph-modals/REMOVE_NODE":
      return {
        ...state,
        nodes: state.nodes.filter((node: any) => node.id !== action.nodeId),
      };
    case "graph-modals/REMOVE_LINK":
      return {
        ...state,
        links: state.links.filter(
          (link: any) =>
            link.source.id !== action.sourceId ||
            link.target.id !== action.targetId
        ),
      };

    case "graph-modals/RESET_MODALS":
      return {
        ...state,
        nodes: [],
        links: [],
      };
    case "graph-modals/SET_LINK_VISIBLE":
      return {
        ...state,
        areLinksVisible: action.visible,
      };

    case "graph-modals/ZOOM_TO_FIT":
      return {
        ...state,
        zoomToFitToggler: !state.zoomToFitToggler,
      };
    case "graph-modals/SET_VIEW_TYPE":
      return {
        ...state,
        viewType: action.viewType,
      };
    case "graph-modals/SET_BACKGROUND_TYPE":
      return {
        ...state,
        backgroundType: action.backgroundType,
      };
    case "graph-modals/SET_COLORED_FIELD":
      return {
        ...state,
        coloredField: action.coloredField,
      };
    case "graph-modals/SET_HOVERED_INFO_TYPE":
      return {
        ...state,
        hoveredInfoType: action.hoveredInfoType,
      };
    default:
      return state;
  }
};

export default modal;
