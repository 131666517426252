import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Form, Modal } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { updateCurrentQueryDetiails } from "../../../actions/query";
import { useStore } from "../../../reducers/zustand/store";
import { Dispatch, QueryRequest, store } from "../../../utils/types";
import InputNumberWithSlider from "../InputNumberWithSlider";

const textMuted: React.CSSProperties = { color: "#999" };
const AbsoluteSimilarity = ({ values, setValues }: any) => {
  const { formFields, patchFormFields } = useStore((state: any) => ({
    formFields: state.formFields,
    patchFormFields: state.patchFormFields,
  }));
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (value: number) => {
    patchFormFields({ absoluteSimilarity: value });
  };
  return (
    <div id="epp" className="w-full mt-5">
      <Form.Item
        labelCol={{ span: 24 }}
        label={
          <>
            <p>
              Confidence Score <span style={textMuted}> (Optional)</span>
            </p>
            <Button
              onClick={showModal}
              style={{
                border: "none",
                width: "fit-content",
                display: "inline",
              }}
            >
              <QuestionCircleOutlined />
            </Button>
            <Modal
              title="Confidence Score"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
            >
              <p>
                The score is ranging from 0 to 1. Higher score indicates high
                possibility of inhibited or activated efficacy. We recommend 0.4
                as a default.
              </p>
            </Modal>
          </>
        }
        initialValue={0.4}
      >
        <div>
          <InputNumberWithSlider
            defaultValue={0.4}
            value={formFields.absoluteSimilarity}
            onChange={onChange}
            min={0}
            max={1}
            step={0.1}
          />
        </div>
      </Form.Item>
    </div>
  );
};

const mapStateToProps = (state: store) => ({
  values: state.query.currentQueryDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setValues: (currentQueryDetails: QueryRequest) =>
    dispatch(updateCurrentQueryDetiails(currentQueryDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AbsoluteSimilarity);
