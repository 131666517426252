import { useRef } from "react";
import Accordion from "../common-components/Accordion";
import QueryResultArea from "../common-components/QueryResultArea";

function ResultArea() {
  const graphWrapperRef = useRef<any>(null);

  return (
    <div className="flex w-full">
      <div className="relative justify-end flex-1 w-full h-full md:flex-row">
        <div className="z-40 flex w-full overflow-hidden">
          <QueryResultArea graphWrapperRef={graphWrapperRef} />
        </div>
        <div className="absolute z-50">
          <Accordion graphWrapperRef={graphWrapperRef} />
        </div>
      </div>
    </div>
  );
}

export default ResultArea;
