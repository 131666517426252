import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Select, Tooltip } from "antd";
import { connect } from "react-redux";
import { updateCurrentQueryDetiails } from "../../../actions/query";
import { Dispatch, PertType, QueryRequest, store } from "../../../utils/types";
import { usePrevious } from "../../../utils/usePrevious";
import { useStore } from "../../../reducers/zustand/store";
import { QuestionCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const PertTypeSelect = ({ values, setValues, categoryStructure }: any) => {
  const { formFields, patchFormFields } = useStore((state: any) => ({
    formFields: state.formFields,
    patchFormFields: state.patchFormFields,
  }));

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (value: string) => {
    patchFormFields({
      pertType: value,
    });
  };

  return (
    <div id="cohort-disease-select" className="mt-5">
      <Form.Item
        labelCol={{ span: 24 }}
        label={
          <>
            <div>Perturbation Type of Data </div>
            <Button
              onClick={showModal}
              style={{
                border: "none",
                width: "fit-content",
                display: "inline",
              }}
            >
              <QuestionCircleOutlined />
            </Button>
            <Modal
              title="Pertubation Type of Data"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
            >
              <p>
                Which perturbation data would you like to use for prediction?
                <br /> <br />
                <strong>SH:</strong> Predict drugs or target genes based on the
                transcriptomics profiles of cell lines perturbed by gene
                knockdown (shRNA). <br /> <br />
                <strong>XPR:</strong>
                Predict drugs or target genes based on the transcriptomics
                profiles of cell lines perturbed by gene knockout (CRISPR-CAS9).
                <br /> <br />
                <strong>OE:</strong> Predict drugs or target genes based on the
                transcriptomics profiles of cell lines perturbed by gene
                overexpression (Plasmid). <br /> <br />
                <strong>Note:</strong> We recommend ‘SH’ and ‘XPR’ to predict
                the inhibition association, and ‘OE’ to predict the activation
                association.
              </p>
            </Modal>
          </>
        }
        required
      >
        <Select
          value={formFields.pertType}
          onChange={onChange}
          placeholder="sh/oe/xpr"
          className="w-full rounded-select"
        >
          {Object.keys(PertType).map((o: string, i: any) => (
            <Option key={i} value={o}>
              {o}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

const mapStateToProps = (state: store) => ({
  values: state.query.currentQueryDetails,
  categoryStructure: state.dataTypes.catStructure,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setValues: (currentQueryDetails: QueryRequest) =>
    dispatch(updateCurrentQueryDetiails(currentQueryDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PertTypeSelect);
