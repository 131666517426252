import React, { useEffect, useState } from "react";
import { Button, Empty, Form, Modal, Select } from "antd";
import { connect } from "react-redux";
import { Dispatch, QueryRequest, store } from "../../../utils/types";
import { updateCurrentQueryDetiails } from "../../../actions/query";
import { useStore } from "../../../reducers/zustand/store";
import useAPI from "../../../api/useAPI";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { generateCellLineWithDiseases } from "../../../utils/helper";

const { Option } = Select;

const CellLine = ({ values, setValues }: any) => {
  const api = useAPI();
  const { formFields, patchFormFields, cellLineData, setCellLineData } =
    useStore((state: any) => ({
      formFields: state.formFields,
      patchFormFields: state.patchFormFields,
      cellLineData: state.cellLineData,
      setCellLineData: state.setCellLineData,
    }));
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (value: string) => {
    patchFormFields({ cellLine: value });
  };

  const clearCellLine = () => {
    setCellLineData([]);
    patchFormFields({ cellLine: null });
  };

  const fetchCellLineData = async () => {
    api.getCellLineData(formFields.geneOrDrug, formFields.pertType, (res) => {
      if (res.data?.data?.length > 0) {
        const cellLineData = ["All", ...res.data.data];
        setCellLineData(cellLineData);
      } else {
        setCellLineData([]);
        patchFormFields({ cellLine: null });
      }
    });
  };

  useEffect(() => {
    const isDataValid =
      formFields.geneOrDrug !== null && formFields.pertType !== null;
    isDataValid ? fetchCellLineData() : clearCellLine();
  }, [formFields.pertType, formFields.geneOrDrug]);

  return (
    <div id="corelation" className="w-full mt-5">
      <Form.Item
        required
        labelCol={{ span: 24 }}
        label={
          <>
            <div>Cell Line</div>
            <Button
              onClick={showModal}
              style={{
                border: "none",
                width: "fit-content",
                display: "inline",
              }}
            >
              <QuestionCircleOutlined />
            </Button>
            <Modal
              title="Cell Line"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
            >
              <p>
                Please select a specific cell line or <strong>ALL</strong>. If
                the user selected <strong>ALL</strong>, the prediction is
                conducted based on all available cell lines.
              </p>
            </Modal>
          </>
        }
      >
        <Select
          value={formFields.cellLine}
          onChange={onChange}
          placeholder="Select Cell Line"
          className="w-full rounded-select"
          allowClear
          onClear={() => {
            patchFormFields({ cellLine: null });
          }}
          notFoundContent={
            <Empty
              description={"No available cell line for this query!"}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          }
        >
          {cellLineData &&
            cellLineData.map((cell_line: any) => (
              <Option key={cell_line} value={cell_line}>
                {generateCellLineWithDiseases(cell_line)}
              </Option>
            ))}
        </Select>
      </Form.Item>
    </div>
  );
};

const mapStateToProps = (state: store) => ({
  values: state.query.currentQueryDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setValues: (currentQueryDetails: QueryRequest) =>
    dispatch(updateCurrentQueryDetiails(currentQueryDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CellLine);
