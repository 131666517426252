import {
  ColoredField,
  LinkWithNodes,
  Node,
  NodesInfo,
  QueryResponse,
} from "./types";
import hslToHex from "hsl-to-hex";

// We are also using the keys in this object
// Be careful if you think delete or update
const allFields: any = {
  ALL: {
    symbol: "Name",
    location: "Analyte Type",
    cluster: "Cluster",
  },
  Species: {
    symbol: "Species",
    info1: "Genus",
    info2: "Family",
    info3: "Order",
    location: "Analyte Type",
    cluster: "Cluster",
    color: "#0369a1",
    colorOnHover: "#05A4F9",
  },
};

export const fieldExplanationMap: any = Object.keys(allFields).reduce(
  (acc: any, curr) => {
    acc[curr] = { ...allFields[curr] };
    delete acc[curr].color;
    delete acc[curr].colorOnHover;
    return acc;
  },
  {}
);

export const linkColors: any = {
  DRUG: "purple",
  GENE: "#0369a1",
};

export const colorSchema = (location: string) => {
  return allFields[location] && allFields[location].colorOnHover
    ? allFields[location].color
    : "#000";
};

export const colorSchemaHover = (location: string) => {
  return allFields[location] && allFields[location].colorOnHover
    ? allFields[location].colorOnHover
    : "#000";
};

export const includeNodesToLinks = (data: QueryResponse) => {
  data.links.forEach((link: any) => {
    link.source = data.nodes.find((node: Node) => node.id === link.source.id)!;
    link.target = data.nodes.find((node: Node) => node.id === link.target.id)!;
  });
};

export const cropNode = (node: any) => {
  const {
    Degree,
    category,
    cluster,
    id,
    index,
    indexClone,
    links,
    location,
    neighbors,
    symbol,
    fx,
    fy,
    x,
    y,
    __indexColor,
    ...rest
  } = node;

  return rest;
};

export const cropNodeForExport = (node: any) => {
  const {
    category,
    cluster,
    id,
    index,
    indexClone,
    links,
    neighbors,
    vx,
    vy,
    fx,
    fy,
    x,
    y,
    __indexColor,
    ...rest
  } = node;

  return rest;
};

export const nodesInfoOrder = [
  "symbol",
  "color",
  "coloredField",
  "indexClone",
  "Species",
  "Genus",
  "Family",
  "Order",
  "Class",
  "Phylum",
  "Region Enrichment",
  "Gut outflow",
  "Gut inflow",
  "Pathways",
  "Shape",
  "Gram staining",
  "Motility",
  "Oxygen Requirement",
  "Sporulation",
  "Ecosystem",
  "Ecosystem Type",
  "Disease Association",
  "Salinity",
  "cluster",
  "Degree",
  "location",
  "category",
];

export const nodesInfoSorter = (a: string, b: string) => {
  const indexA = nodesInfoOrder.indexOf(a);
  const indexB = nodesInfoOrder.indexOf(b);

  if (indexA < 0 || indexB < 0) {
    return indexA >= 0 ? -1 : indexB >= 0 ? 1 : 0;
  }

  return indexA > indexB ? 1 : indexB > indexA ? -1 : 0;
};

export const includeNodesInfo = (data: QueryResponse) => {
  const result = {} as NodesInfo;

  data.nodes.forEach((node: any) => {
    Object.entries(cropNode(node)).forEach((entry) => {
      const [key, value] = entry;

      if (!result.hasOwnProperty(key)) {
        result[key] = [];
      }

      if (!result[key].includes(value)) {
        result[key].push(value);
      }
    });
  });

  data.nodesInfo = result;
};

export const includeIndexClone = (data: QueryResponse) => {
  data.nodes.forEach((node: any) => {
    node.indexClone = node.index;
  });
};

const getRangeArray = (n: number, max: number) => {
  const range = max / (n + 1); /* 0 and 100 unacceptable */

  const arr = [] as number[];
  for (let i = range; i < max; i += range) {
    arr.push(Math.floor(i));
  }

  return arr;
};

const getHSLColor = (h: number, s: number, l: number) => {
  return [`hsl(${h},${s}%,${l}%)`, `hsl(${h},${s}%,${Math.min(90, l + 30)}%)`];
};

const getRandomColor = () => {
  const h = Math.floor(Math.random() * 359);
  const s = Math.floor(Math.random() * 100);
  const l = Math.floor(Math.random() * 100);

  return getHSLColor(h, s, l);
};

export const generateColors = (n: number) => {
  if (n < 1) {
    return [];
  }

  const colors = [] as string[][];
  const hCount = n < 12 ? n : 12;
  let lCount = Math.ceil(n / 12);

  if (lCount > 4) {
    lCount = 4;
  }

  const hArr = getRangeArray(hCount, 360);
  const lArr = getRangeArray(lCount, 100);

  lArr.reverse().forEach((l) =>
    hArr.forEach((h) => {
      if (colors.length < n) {
        colors.push(getHSLColor(h, 100, l));
      }
    })
  );

  // rest of colors are random generated
  while (colors.length < n) {
    const color = getRandomColor();
    if (!colors.includes(color)) {
      colors.push(color);
    }
  }

  return colors;
};

export const getNodeColor = (node: any, coloredField?: ColoredField) => {
  if (!coloredField) {
    return [colorSchema(node.location), colorSchemaHover(node.location)];
  }

  if (!node.hasOwnProperty(coloredField.key)) {
    return ["#ddd", "#fff"];
  }

  const result = coloredField.colors[node[coloredField.key]];

  return result
    ? result
    : [colorSchema(node.location), colorSchemaHover(node.location)];
};

export const getColorsForField = (nodesInfo: any, key: string) => {
  const colors = {} as { [propertyName: string]: string[] };

  if (!nodesInfo?.hasOwnProperty(key)) {
    return colors;
  }

  const values = nodesInfo[key];
  const generatedColors = generateColors(values.length);

  values.forEach((v: string, i: number) => {
    colors[v] = generatedColors[i];
  });

  return colors;
};

export const parseHSLColor = (hslColor: string) => {
  const regexp = /hsl\((\d+),(\d+)%,(\d+)%\)/g;
  const color = regexp.exec(hslColor)?.slice(1);
  return color?.map((c) => parseInt(c));
};

export const getHexColorFromHsl = (hslColor: string) => {
  const [h, s, l]: any = parseHSLColor(hslColor);

  return hslToHex(h, s, l);
};
