import { Content } from "antd/lib/layout/layout";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { baseUrl } from "../config/constants";
import QueryResultArea from "./common-components/QueryResultArea";
import CiteAndDataPage from "./pages/CiteAndDataPage";
import HelpPage from "./pages/HelpPage";
import MainPage from "./pages/MainPage";
import QueryPage from "./pages/QueryPage";
import ResultArea from "./pages/ResultArea";
import UploadPage from "./pages/UploadPage";
import ApiPage from "./pages/ApiPage";

const AppRouter = () => {
  return (
    <Switch>
      <Route path={baseUrl + "/"} component={QueryPage} exact />
      <Route path={baseUrl + "/data"} component={ResultArea} exact />
      <Route path={baseUrl + "/help"} component={HelpPage} exact />
      <Route path={baseUrl + "/upload"} component={UploadPage} exact />
      <Route
        path={baseUrl + "/cite-and-data"}
        component={CiteAndDataPage}
        exact
      />
      <Route
        path={baseUrl + "/programming-interface"}
        component={ApiPage}
        exact
      />
      <Redirect to={baseUrl} />
    </Switch>
  );
};

export default AppRouter;
