import React from "react";
import GeneOrDrugTextField from "./queryArea/GeneOrDrugTextField";
import PertTypeSelect from "./queryArea/PertType";
import EffectTypeSelect from "./queryArea/EffectTypeSelect";
import EdgeSignificance from "./queryArea/AbsoluteSimilarity";
import CellLine from "./queryArea/CellLine";
import NodeLimit from "./queryArea/NodeLimit";
import Search from "./queryArea/Search";
import ExampleArea from "./queryArea/ExampleArea";

// https://react-hook-form.com/get-started/#IntegratingwithUIlibraries
const QueryArea = () => {
  return (
    <div>
      <ExampleArea />

      <GeneOrDrugTextField />

      <PertTypeSelect />

      <EffectTypeSelect />

      <CellLine />

      <EdgeSignificance />

      <Search />
      <br />
    </div>
  );
};

export default QueryArea;
