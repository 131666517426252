import * as React from "react";
import DrawGraph from "./DrawGraph";
import TableView from "./TableView";
import useSize from "../../hooks/use-size";
import { Dispatch, store } from "../../utils/types";
import { connect } from "react-redux";
import { setViewType } from "../../actions/graph-modals";
import { viewTypes } from "../../reducers/graph-modals";
import { useStore } from "../../reducers/zustand/store";
import { link } from "fs";

const getBackgroundStyle = (backgroundType: string) => {
  let filename = "";
  const options: any = { bgOpacity: 0.7 };

  switch (backgroundType) {
    case "Grid 1":
      filename = "grid-1.png";
      options.backgroundRepeat = "no-repeat";
      options.backgroundSize = "100% 100%";
      break;
    case "Grid 2":
      filename = "grid-2.png";
      options.backgroundRepeat = "no-repeat";
      options.backgroundSize = "100% 100%";
      break;
    case "Dots 1":
      filename = "dots-1.png";
      break;
    case "Dots 2":
      filename = "dots-2.png";
      options.bgOpacity = 0.8;
      break;
  }

  return filename
    ? {
        backgroundImage: `linear-gradient(rgba(255,255,255,${
          options.bgOpacity
        }), rgba(255,255,255,${options.bgOpacity})),  url(${
          process.env.PUBLIC_URL + "/" + filename
        })`,
        ...options,
      }
    : {};
};

const SizeAwareGraph = ({ graphWrapperRef, backgroundType, size }: any) => {
  const { links, nodes, setLinks, setNodes, setViewType, viewType } = useStore(
    (state: any) => ({
      links: state.links,
      nodes: state.nodes,
      viewType: state.viewType,
      setViewType: state.setViewType,
      setNodes: state.setNodes,
      setLinks: state.setLinks,
    })
  );
  const target = React.useRef(null);

  if (viewType === viewTypes[2]) {
    return (
      <div ref={target} className="w-full h-full">
        <div className="bg-gray-50">
          <TableView tableType="Drug Table" />
        </div>
      </div>
    );
  } else if (viewType === viewTypes[1]) {
    return (
      <div ref={target} className="w-full h-full">
        <div className="bg-gray-50">
          <TableView tableType="Edge Table" />
        </div>
      </div>
    );
  } else {
    return (
      <div ref={target} className="w-full h-full">
        <div ref={graphWrapperRef} style={getBackgroundStyle(backgroundType)}>
          <DrawGraph
            queryResponse={{
              nodes: nodes,
              links: links,
              nodesInfo: {},
            }}
            size={size}
          />
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state: store) => ({
  viewType: state.graphModals.viewType,
  backgroundType: state.graphModals.backgroundType,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setViewTypeAction: (viewType: string) => dispatch(setViewType(viewType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SizeAwareGraph);
