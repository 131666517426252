import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Modal, Select } from "antd";
import { connect } from "react-redux";
import {
  Dispatch,
  Node,
  PertType,
  QueryRequest,
  store,
} from "../../../utils/types";
import { updateCurrentQueryDetiails } from "../../../actions/query";
import DelayedAutoComplete from "../DelayedAutoComplete";
import isEmpty from "lodash.isempty";
import { useStore } from "../../../reducers/zustand/store";
import { QuestionCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const GeneOrDrugTextField = ({ values, setValues, categoryStructure }: any) => {
  const { formFields, patchFormFields } = useStore((state: any) => ({
    formFields: state.formFields,
    patchFormFields: state.patchFormFields,
  }));
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  console.log(formFields);

  return (
    <div id="gene-or-drug-name-select">
      <Form.Item
        labelCol={{ span: 24 }}
        label={
          <>
            <div>Gene or Drug</div>
            <Button
              onClick={showModal}
              style={{
                border: "none",
                width: "fit-content",
                display: "inline",
              }}
            >
              <QuestionCircleOutlined />
            </Button>
            <Modal
              title="Gene or Drug"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
            >
              <p>
                Please enter a gene symbol or drug name, and select it from the
                drop-down list. <br />
                <br />
                <strong>Note:</strong> if your query did not appear in the
                drop-down list, it means there is no available data for this
                query.
              </p>
            </Modal>
          </>
        }
        required={true}
        initialValue={formFields.geneOrDrug}
      >
        <DelayedAutoComplete
          value={formFields.geneOrDrug}
          delay={200}
          disabled={false}
          placeholder="Please enter a gene or drug name"
        ></DelayedAutoComplete>
      </Form.Item>
    </div>
  );
};

const mapStateToProps = (state: store) => ({
  values: state.query.currentQueryDetails,
  categoryStructure: state.dataTypes.catStructure,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setValues: (currentQueryDetails: QueryRequest) =>
    dispatch(updateCurrentQueryDetiails(currentQueryDetails)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneOrDrugTextField);
