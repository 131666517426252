import { AutoComplete, Select } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { updateCurrentQueryDetiails } from "../../actions/query";
import useAPI from "../../api/useAPI";
import useDebounce from "../../hooks/use-debounce";
import { useStore } from "../../reducers/zustand/store";
import { func, QueryRequest, store, Dispatch } from "../../utils/types";
const { Option } = Select;

type Props = {
  disabled: boolean;
  delay: number;
  placeholder?: string;
  results?: string[];
};
const DelayedAutoComplete = ({
  disabled,
  delay,
  placeholder,
  values,
  setValues,
}: any) => {
  const api = useAPI();
  const { formFields, patchFormFields } = useStore((state: any) => ({
    formFields: state.formFields,
    patchFormFields: state.patchFormFields,
  }));
  const [options, setOptions] = useState<{ value: string }[]>([]);

  const onSearch = (searchText: string) => {
    api.fetchAutoCompleteData(searchText, (res) => {
      if (res) {
        const options = res.data.data.map((item: any) => ({ value: item }));
        setOptions(options);
      } else {
        setOptions([]);
      }
    });
  };

  const onSelect = (data: string) => {
    const filteredIndex = data.lastIndexOf(" ");
    const filteredData = data.substring(0, filteredIndex);
    patchFormFields({
      geneOrDrug: filteredData,
      cellLine: null,
    });
  };

  const onClear = () => {
    patchFormFields({
      geneOrDrug: null,
      cellLine: null,
    });
  };

  return (
    <>
      <AutoComplete
        options={options}
        onSelect={onSelect}
        onSearch={onSearch}
        placeholder={placeholder}
        disabled={disabled}
        allowClear={true}
        onClear={onClear}
        value={formFields.geneOrDrug}
        onChange={(e) => {
          patchFormFields({
            geneOrDrug: e,
            cellLine: null,
          });
        }}
      />
    </>
  );
};

const mapStateToProps = (state: store) => ({
  values: state.query.currentQueryDetails,
  categoryStructure: state.dataTypes.catStructure,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setValues: (currentQueryDetails: QueryRequest) =>
    dispatch(updateCurrentQueryDetiails(currentQueryDetails)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DelayedAutoComplete);
