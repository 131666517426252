import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch, func, store } from "../../utils/types";
import {
  queryWithCurrentQueryDetails,
  resetQueryResponse,
  setShowTutorial,
  updateLastQueryString,
} from "../../actions/query";
import { AiOutlineSearch, AiOutlineApi } from "react-icons/ai";
import { IoMdHelpCircleOutline, IoMdCodeWorking } from "react-icons/io";
import { BiData, BiInfoCircle, BiNote, BiText } from "react-icons/bi";
import { baseUrl } from "../../config/constants";
import { UploadOutlined } from "@ant-design/icons";

const { Header } = Layout;

const getNavbarButtonStyle = (buttonName: string, selected: string) => {
  return (
    "flex items-center px-6 py-1 space-x-2 text-base " +
    (selected === buttonName ? "navbar-button-active" : "button-curve")
  );
};

type Props = {
  setShowTutorialAction: func;
  firstInitDataTypes: string;
  updateLastQueryStringAction: func;
  queryWithCurrentQueryDetailsAction: func;
  resetQueryResponseAction: func;
};
const Navbar = ({
  setShowTutorialAction,
  firstInitDataTypes,
  updateLastQueryStringAction,
  queryWithCurrentQueryDetailsAction,
  resetQueryResponseAction,
}: Props) => {
  const history = useHistory();
  const [selected, setSelected] = useState("/");
  const queryStrings = useLocation();

  useEffect(() => {
    setSelected(
      [
        "/help",
        "/cite-and-data",
        "/upload",
        "/search",
        "/programming-interface",
      ].includes(queryStrings.pathname)
        ? queryStrings.pathname
        : "/"
    );
  }, [queryStrings]);

  return (
    <Header className="justify-center overflow-y-hidden shadow navbar navbar-1">
      <div className="flex flex-col items-center justify-between w-full shadow md:flex-row navbar navbar-1 navbar-inner ">
        <Link
          to={baseUrl + "/"}
          className="flex items-center self-center mx-10 mt-4 text-black md:mt-0 hover:text-black"
          onClick={() => {
            updateLastQueryStringAction("");
            resetQueryResponseAction();
            setTimeout(() => window.location.reload(), 0);
          }}
        >
          <div className="text-2xl font-light text-gray-700">
            <span>T-Drug</span>
          </div>
        </Link>

        <div className="flex flex-row justify-around mx-10">
          <button
            className="flex items-center justify-center h-16 mx-1 space-x-1 btn-transparent"
            //onClick={() => queryWithCurrentQueryDetailsAction(history, true)}
          >
            <Link to={"/"} className="text-gray-700 hover:text-gray-700">
              <div className={getNavbarButtonStyle("/", selected)}>
                <AiOutlineSearch /> <span>Search</span>
              </div>
            </Link>
          </button>

          <Link
            to={baseUrl + "/cite-and-data"}
            className="text-gray-700 hover:text-gray-700"
          >
            <button className="flex items-center justify-center h-16 mx-1 space-x-1 btn-transparent">
              <div className={getNavbarButtonStyle("/cite-and-data", selected)}>
                <BiNote /> <span>Cite & Data</span>
              </div>
            </button>
          </Link>
          <Link
            to={baseUrl + "/upload"}
            className="text-gray-700 hover:text-gray-700"
          >
            <button className="flex items-center justify-center h-16 mx-1 space-x-1 btn-transparent">
              <div className={getNavbarButtonStyle("/upload", selected)}>
                <UploadOutlined /> <span>Upload</span>
              </div>
            </button>
          </Link>

          <Link
            to={baseUrl + "/programming-interface"}
            className="text-gray-700 hover:text-gray-700"
          >
            <button className="flex items-center justify-center h-16 mx-1 space-x-1 btn-transparent">
              <div
                className={getNavbarButtonStyle(
                  "/programming-interface",
                  selected
                )}
              >
                <IoMdCodeWorking /> <span>API</span>
              </div>
            </button>
          </Link>

          <Link
            to={baseUrl + "/help"}
            className="text-gray-700 hover:text-gray-700"
          >
            <button className="flex items-center justify-center h-16 mx-1 space-x-1 btn-transparent">
              <div className={getNavbarButtonStyle("/help", selected)}>
                <IoMdHelpCircleOutline /> <span>Help</span>
              </div>
            </button>
          </Link>
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = (state: store) => ({
  firstInitDataTypes: state.dataTypes.firstInitData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setShowTutorialAction: (showTutorial: boolean) =>
    dispatch(setShowTutorial(showTutorial)),
  queryWithCurrentQueryDetailsAction: (history: any, force?: boolean) =>
    dispatch(queryWithCurrentQueryDetails(history, force)),
  updateLastQueryStringAction: (queryString: string) =>
    dispatch(updateLastQueryString(queryString)),
  resetQueryResponseAction: () => dispatch(resetQueryResponse()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
