import helpSection1Image from "../../img/help-img/helpSection1_img.png";
import helpSection2_1_Image from "../../img/help-img/helpSection2-1_img.png";
import helpSection2_2_Image from "../../img/help-img/helpSection2-2_img.png";
import helpSection2_3_Image from "../../img/help-img/helpSection2-3_img.png";
import helpSection2_4_Image from "../../img/help-img/helpSection2-4_img.png";
import helpSection2_5_1_Image from "../../img/help-img/helpSection2-5_1_img.png";
import helpSection2_5_2_Image from "../../img/help-img/helpSection2-5_2_img.png";
import helpSection2_6_Image from "../../img/help-img/helpSection2-6_img.png";
import helpSection3_2_Image from "../../img/help-img/helpSection3-2_img.png";
import helpSection4_Image from "../../img/help-img/helpSection4_img.png";
import helpSection3_1_1_Image from "../../img/help-img/helpSection3-1_1_img.png";
import helpSection3_1_2_Image from "../../img/help-img/helpSection3-1_2_img.png";
import helpSection3_1_3_Image from "../../img/help-img/helpSection3-1_3_img.png";
const HelpPage = () => {
  return (
    <div className="h-screen overflow-auto bg-white">
      <div className="max-w-5xl px-10 mx-auto my-10">
        <div className="pb-8 text-4xl font-thin">
          <span>Help</span>
        </div>
        <div className="font-light text-blue-400">
          <div className="pb-6">
            <hr />
            <p className="mt-4 text-black">Table of Contents</p>
            <ol className="px-2 mb-4">
              <li className="px-2">
                <a href="#algorithm">1. The algorithm of T-Drug</a>
              </li>
              <li className="px-2">
                <div className="">
                  <a href="#predictAssociations">
                    2. How to predict the drug-target associations using T-Drug
                  </a>
                  <ol className="px-10">
                    <li>
                      {" "}
                      <a href="#overview">Overview</a>
                    </li>
                    <li>
                      {" "}
                      <a href="#queryGeneOrDrug">Query a gene or drug</a>
                    </li>
                    <li>
                      {" "}
                      <a href="#selectPertType">
                        Select the perturbation data type
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="#selectEffectType">Select the effect type</a>
                    </li>
                    <li>
                      {" "}
                      <a href="#selectCellLine">Select the cell line</a>
                    </li>
                    <li>
                      {" "}
                      <a href="#selectConfidenceScore">
                        Select a confidence score cutoff
                      </a>
                    </li>
                  </ol>
                </div>
              </li>
              <li className="px-2">
                <div className="">
                  <a href="#result">3. Result</a>
                  <ol className="px-10">
                    <li>
                      {" "}
                      <a href="#viewTable">View the result table</a>
                    </li>
                    <li>
                      {" "}
                      <a href="#viewNetwork">View the result network</a>
                    </li>
                  </ol>
                </div>
              </li>
              <li className="px-2">
                <a href="#browserCompatibility">4. Browser Compatibility</a>
              </li>
              <li className="px-2">
                <a href="#api">5. API</a>
              </li>
              <li className="px-2">
                <a href="#dataAvailability">6. Data availability</a>
              </li>
              <li className="px-2">
                <a href="#uploadRNA">7. Upload RNA-seq data</a>
              </li>
            </ol>
            <hr />
          </div>
        </div>
        <ol className="">
          <li id="algorithm">
            <div className="font-light">
              <div className="pb-6 text-base">
                <p className="font-semibold ">1. The algorithm of T-Drug</p>
                <div className="w-full px-7">
                  <p>
                    The construction of T-Drug is based on our previously
                    proposed profile-based drug repositioning approach which
                    performs an integrated analysis of transcriptomics signature
                    profiles of human cells induced by drug treatment and
                    genetic perturbation. This approach hypothesizes that a drug
                    is considered to have an inhibitory or activated effect on
                    the expression of a target gene if this drug leads to a wide
                    perturbation on the gene expression landscape in human
                    cells, similar to the effect of genetic perturbation of the
                    target gene. The algorithm is shown below. In breif, there
                    are four steps. <br />
                    <br />
                    Step 1. Construct a similarity matrix between
                    compound-induced and genetic-perturbed transcriptomics
                    signature profiles. <br />
                    Step 2. Optimize the similarity matrix by screening the
                    optimal drug dose/treated time points and
                    shRNA/CRISPR-Cas9/plasmid perturbagens. <br />
                    Step 3. Rank the compounds based on confidence scores and
                    select the promising compounds. Step 4. Validate the drug
                    efficacy in in vitro model.
                  </p>
                  <img
                    alt="algorithm for t-drug"
                    className="w-8/12 h-auto m-auto my-4"
                    src={helpSection1Image}
                  />
                </div>
              </div>
            </div>
          </li>

          <li id="predictAssociations">
            <div className="font-light">
              <div className="pb-6 text-base">
                <p className="font-semibold ">
                  2. How to predict the drug-target associations using T-Drug
                </p>
                <div className="w-full px-7">
                  <ol className="" type="1">
                    <li className="mt-4 font-semibold" id="overview">
                      Overview <br />
                      <div className="font-light">
                        At the top right of main page, we provided two template
                        examples. New users can directly click the links to test
                        the tool. In addition, we provided an explaination icon
                        (i.e., question mark icon) beside each option to help
                        users to have a better understanding of these options.
                        <img
                          alt="overview of query area"
                          className="w-6/12 h-auto m-auto my-4"
                          src={helpSection2_1_Image}
                        />
                      </div>
                    </li>
                    <li className="mt-4 font-semibold" id="queryGeneOrDrug">
                      Query a gene or drug <br />
                      <div className="font-light">
                        Users can query a gene or drug of interest. Since the
                        gene symbols or drug names can be hard to type, we
                        provided the auto-complete feature to show the closest
                        match to the typed letters. User should manually select
                        one gene or drug from the drop-down list. If the
                        expected gene or drug does not appear in the drop-down
                        list, it means this query is not available in this tool.
                        <img
                          alt="gene or drug query area"
                          className="w-6/12 h-auto m-auto my-4"
                          src={helpSection2_2_Image}
                        />
                      </div>
                    </li>
                    <li className="mt-4 font-semibold" id="selectPertType">
                      Select the perturbation data type <br />
                      <div className="font-light">
                        We provide three genetic-perturbed transcriptomics data
                        types to predict the drug-target associations. Users
                        should select one of them. ‘SH’ represents the
                        transcriptomics profiles of cell lines perturbed by gene
                        knockdown; ‘XPR’ represents the transcriptomics profiles
                        of cell lines perturbed by gene knockout; ‘OE’
                        represents the transcriptomics profiles of cell lines
                        perturbed by gene overexpression. We recommend ‘SH’ and
                        ‘XPR’ to predict the inhibition associations, and ‘OE’
                        to predict the activation associations.
                        <img
                          alt="select pertubation data type area"
                          className="w-6/12 h-auto m-auto my-4"
                          src={helpSection2_3_Image}
                        />
                      </div>
                    </li>
                    <li className="mt-4 font-semibold" id="selectEffectType">
                      Select the effect type <br />
                      <div className="font-light">
                        This tool can predict the inhibition or activation
                        association between drug and target. To generate the
                        corresponding result, user should select ‘Inhibition’ or
                        ‘Activation’.
                        <img
                          alt="select effect type area"
                          className="w-6/12 h-auto m-auto my-4"
                          src={helpSection2_4_Image}
                        />
                      </div>
                    </li>
                    <li className="mt-4 font-semibold" id="selectCellLine">
                      Select the cell line <br />
                      <div className="font-light">
                        Further, user can select a specific cell line which
                        represents the specific tissue or disease of interest
                        from the drop-down list. Users also can select ‘All’
                        which means T-Drug will predict the result using all the
                        available cell lines. Notably, only the available cell
                        lines will appear in the drop-down list after users fix
                        the queried gene or drug, perturbation data type, and
                        effect type terms. If the expected cell line does not
                        exist in the drop-down list, we suggest selecting ‘All’
                        for the prediction.
                        <img
                          alt="select cell line area"
                          className="w-6/12 h-auto m-auto my-4"
                          src={helpSection2_5_1_Image}
                        />
                        In some cases, it shows a warning that there is no
                        available cell line for the query. This warning can
                        happen when there is no available cell line under the
                        selected perturbation data type for the queried gene or
                        drug. For example, as shown below, we do not have ‘OE’
                        transcriptomics data associated with MCM6 overexpression
                        in any cell line. In this case, we suggest users to try
                        other perturbation data types, ‘SH’ or ‘XPR’. In
                        addition, this warning can also happen when the queried
                        gene or drug’s name is not correctly typed or does not
                        exist in our platform.
                        <img
                          alt="empty select cell line area"
                          className="w-6/12 h-auto m-auto my-4"
                          src={helpSection2_5_2_Image}
                        />
                      </div>
                    </li>
                    <li
                      className="mt-4 font-semibold"
                      id="selectConfidenceScore"
                    >
                      Select a confidence score cutoff <br />
                      <div className="font-light">
                        User can select a confidence score cutoff. 0.4 is set as
                        default based on our empirical in vitro validation.
                        Through adjusting this cutoff lower, users can obtain
                        more drug-target candidates. Through adjusting this
                        cutoff higher, users can obtain drug-target candidates
                        with higher confidence. Finally, users can click
                        ‘Search’ tab to generate the result.
                        <img
                          alt="confidence score area"
                          className="w-6/12 h-auto m-auto my-4"
                          src={helpSection2_6_Image}
                        />
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </li>

          <li id="result">
            <div className="font-light">
              <div className="pb-6 text-base">
                <p className="font-semibold ">3. Result</p>
                <div className="w-full px-7">
                  <ol className="" type="1">
                    <li className="mt-4 font-semibold" id="viewTable">
                      View the result table <br />
                      <div className="font-light">
                        As default, the predicted drug-target assciations are
                        presented by table. If users have selected a specific
                        cell line, the result table includes drug, target,
                        effect type, selected perturbation data type, direction
                        of confidence score, selected cell line and abosolute
                        values of confidence scores. The predicted drug-target
                        associations are ranked based on the descending order of
                        confidence scores. To facilitate the biological
                        understanding and interpretation, the drugs have been
                        linked with PubChem database
                        (https://pubchem.ncbi.nlm.nih.gov/) which describes the
                        structure, molecular formula, chemical and physical
                        properties, and the genes have been linked with
                        GeneCards database (version 5.11,{" "}
                        <a href="https://www.genecards.org/">
                          https://www.genecards.org/
                        </a>
                        ) which describes the biological function, involved
                        pathways and association with different diseases. The
                        result table is freely downloaded by clicking the
                        ‘Export’ icon.
                        <img
                          alt="overview of query area"
                          className="w-8/12 h-auto m-auto my-4"
                          src={helpSection3_1_1_Image}
                        />
                        If users have selected ‘All’ (all the available cell
                        lines) for prediction, the table result provides the
                        ‘Num Cells’ indicating the number of cell lines
                        including a specific drug- target association, ‘Num Hit
                        Cells’ indicating the number of hit cell lines in which
                        this drug-target association is higher than or equal to
                        the confidence score cutoff, ‘Hit Rate (%)’ calculated
                        as Num Hit Cells/Num Cells*100%, and ‘Hit Cells’
                        indicating the list of hit cell lines. As default, the
                        predicted drug-target associations are still ranked
                        based on the descending order of confidence scores. In
                        addition, users can freely rank the results based on the
                        values of ‘Num Cells’, ‘Num Hit Cells’, ‘Hit Rate (%)’,
                        and ‘Hit Cells’ by clicking the ranking icons beside
                        these terms.
                        <img
                          alt="overview of query area"
                          className="w-8/12 h-auto m-auto my-4"
                          src={helpSection3_1_2_Image}
                        />
                        Notebly, if users obtain the below warning showing that
                        there is no result, please decrease the confidence score
                        cutoff and search again.
                        <img
                          alt="overview of query area"
                          className="w-8/12 h-auto m-auto my-4"
                          src={helpSection3_1_3_Image}
                        />
                      </div>
                    </li>
                    <li className="mt-4 font-semibold" id="viewNetwork">
                      View the result network <br />
                      <div className="font-light">
                        Users can change the visualization into network by
                        clicking the ‘Graph View’ icon. The generated network
                        only includes the top 50 drug-target associations based
                        on the highest confidence scores, avoiding browser
                        unresponsiveness.
                        <img
                          alt="view result network"
                          className="w-8/12 h-auto m-auto my-4"
                          src={helpSection3_2_Image}
                        />
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </li>

          <li id="browserCompatibility">
            <div className="font-light">
              <div className="pb-6 text-base">
                <p className="font-semibold ">4. Browser Compatibility</p>
                <div className="w-full px-7">
                  <p>
                    We have tested this web-tool in the following operating
                    systems.
                  </p>
                  <img
                    alt="browser compatibility"
                    className="w-10/12 h-auto m-auto my-4"
                    src={helpSection4_Image}
                  />
                </div>
              </div>
            </div>
          </li>

          <li id="api">
            <div className="font-light">
              <div className="pb-6 text-base">
                <p className="font-semibold ">5. API</p>
                <div className="w-full px-7">
                  <p>
                    To enable more extensive and efficient queries, we
                    implemented programmatic access to the platform based on the
                    Python package, which needs Python version 3.7.10 or above.
                    Please refer to the ‘API’ page.
                  </p>
                </div>
              </div>
            </div>
          </li>

          <li id="dataAvailability">
            <div className="font-light">
              <div className="pb-6 text-base">
                <p className="font-semibold ">6. Data availability</p>
                <div className="w-full px-7">
                  <p>
                    Users can download the target, drug and cell line meta data,
                    and similarity matrices of all the available cell lines in
                    this website. Please refer to the ‘Cite&amp;Date’ page.
                  </p>
                </div>
              </div>
            </div>
          </li>

          <li id="uploadRNA">
            <div className="font-light">
              <div className="pb-6 text-base">
                <p className="font-semibold ">7. Upload RNA-seq data</p>
                <div className="w-full px-7">
                  <p>
                    We encourage users to upload their own RNA-seq data of cells
                    perturbed by drug treatment or genetic perturbation, which
                    can be used as an extended resource. The uploaded data will
                    be kept private and not viewable by anyone without
                    permission. For details, please refer to the ‘Upload’ page.
                  </p>
                </div>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default HelpPage;
