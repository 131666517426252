const ApiPage = () => {
  return (
    <div className="h-screen overflow-auto bg-white">
      <div className="max-w-5xl px-10 mx-auto my-10">
        <div className="pb-8 text-4xl font-thin">
          <span>API Access</span>
        </div>
        <div className="font-light">
          For now, Drug Repositioning support programmatic access using Python.
          In order to do that, please refer the&nbsp;
          <a href="https://github.com/bashbiotech/drug-repositioning-api">
            GitHub Page
          </a>
        </div>
      </div>
    </div>
  );
};

export default ApiPage;
