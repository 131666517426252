import { useMemo, useRef, useState } from "react";
import { Table, Tooltip } from "antd";
import {
  LinkWithNodes,
  Node,
  PertType,
  QueryRequest,
  QueryResponse,
} from "../../utils/types";
import { EDGES } from "../../utils/table-columns";
import { useStore } from "../../reducers/zustand/store";
import { connect } from "react-redux";
import { Dispatch, store } from "../../utils/types";
import { generateCellLineWithDiseases } from "../../utils/helper";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";

type Props = {
  tableType: string;
  values: QueryRequest;
};

export const determineDir = (values: any) => {
  switch (values.pertType) {
    case PertType.SH:
      return values.isActivator ? "Negative" : "Positive";
    case PertType.OE:
      return values.isActivator ? "Positive" : "Negative";
    case PertType.XPR:
      return values.isActivator ? "Negative" : "Positive";
  }
};

const TableView = ({ tableType, values }: Props) => {
  const { links, nodes, viewType, formFields } = useStore((state: any) => ({
    links: state.links,
    nodes: state.nodes,
    setNodes: state.setNodes,
    setLinks: state.setLinks,
    viewType: state.viewType,
    formFields: state.formFields,
  }));

  const EdgeTableColumns: ColumnsType<any> = useMemo(() => {
    const TableColumsForAllCellLineType = [
      {
        title: "Drug",
        dataIndex: "start",
        key: "start",
        render: (node: Node) => {
          return node.link ? (
            <a href={node.link} target="_blank" rel="noopener noreferrer">
              {node.search_index}
            </a>
          ) : (
            node.search_index
          );
        },
      },
      {
        title: "Target",
        dataIndex: "end",
        key: "end",
        render: (node: Node) => {
          return node.link ? (
            <a href={node.link} target="_blank" rel="noopener noreferrer">
              {node.search_index}
            </a>
          ) : (
            node.search_index
          );
        },
      },
      {
        title: "Effect Type",
        dataIndex: "effect_type",
        key: "effect_type",
        render: (effect_type: string) =>
          effect_type === "inhibitor" ? "Inhibition" : "Activation",
      },
      {
        title: (
          <Tooltip
            title={
              "Number of cell lines with both drug and gene perturbation data available."
            }
          >
            Num Cells
          </Tooltip>
        ),
        dataIndex: "total_lines",
        key: "total_lines",
        sorter: (a: any, b: any) => a.total_lines - b.total_lines,
      },
      {
        title: (
          <Tooltip
            title={
              "Number of cell lines in which the confidence score of the investigated drug and target gene is higher than the selected cutoff."
            }
          >
            Num Hit Cells
          </Tooltip>
        ),
        dataIndex: "numberOfHits",
        key: "numberOfHits",
        sorter: (a: any, b: any) => a.numberOfHits - b.numberOfHits,
      },
      {
        title: (
          <Tooltip title={"Num Hit Cells / Num Cells *100%."}>
            Hit Rate (%)
          </Tooltip>
        ),
        dataIndex: "hit_rate",
        key: "hit_rate",
        render: (hit_rate: any) => (hit_rate * 100)?.toFixed(2) ?? 0,
        sorter: (a: any, b: any) => a.hit_rate - b.hit_rate,
      },
      {
        title: (
          <Tooltip title={"Names of the hit cell lines."}>Hit Cells</Tooltip>
        ),
        dataIndex: "cell_lines",
        key: "cell_lines",
        render: (cell_lines: string[]) => cell_lines.join("/"),
      },
      {
        title: (
          <Tooltip
            title={
              " If the user selected a specific cell line, it is equal to the confidence score of  the selected cell line. If the user selected “ALL”, it is equal to the median value of the confidence scores of the hit cell lines."
            }
          >
            Confidence Score
          </Tooltip>
        ),
        dataIndex: "medianCorrelation",
        key: "medianCorrelation",
        render: (hit_rate: any) => hit_rate?.toFixed(4) ?? 0,
        sorter: (a: any, b: any) => a.medianCorrelation - b.medianCorrelation,
      },
    ];

    const TableColumsForSpecificCellLineType = [
      {
        title: "Source",
        dataIndex: "start",
        key: "start",
        render: (node: Node) => {
          return node.link ? (
            <a href={node.link} target="_blank" rel="noopener noreferrer">
              {node.search_index}
            </a>
          ) : (
            node.search_index
          );
        },
      },
      {
        title: "Target",
        dataIndex: "end",
        key: "end",
        render: (node: Node) => {
          return node.link ? (
            <a href={node.link} target="_blank" rel="noopener noreferrer">
              {node.search_index}
            </a>
          ) : (
            node.search_index
          );
        },
      },
      {
        title: "Effect Type",
        dataIndex: "effect_type",
        key: "effect_type",
        render: (effect_type: string) =>
          effect_type === "inhibitor" ? "Inhibition" : "Activation",
      },
      {
        title: "Pert Type",
        dataIndex: "pertType",
        key: "pertType",
      },
      {
        title: "Dir",
        dataIndex: "dir",
        key: "dir",
      },
      {
        title: "Cell Line",
        dataIndex: "cell_line",
        key: "cell_line",
        render: (cell_line: string) => generateCellLineWithDiseases(cell_line),
      },
      {
        title: "Confidence Score",
        dataIndex: "correlation",
        key: "correlation",
        render: (correlation: any) => correlation?.toFixed(4) ?? 0,
      },
    ];

    return [
      ...(formFields.cellLine === "All"
        ? TableColumsForAllCellLineType
        : TableColumsForSpecificCellLineType),
    ];
  }, []);

  const DataSource = links
    .map((link: any) => {
      return {
        start: nodes.find(
          (node: Node) => Number(node.id) === Number(link.start)
        ),
        end: nodes.find((node: Node) => Number(node.id) === Number(link.end)),
        correlation: link.correlation,
        effect_type: link.effect_type,
        cell_line: link.cell_line,
        pertType: values.pertType,
        dir: determineDir(values),
        total_lines: link.total_lines,
        numberOfHits: link.numberOfHits,
        hit_rate: link.hit_rate,
        cell_lines: link.cell_lines,
        medianCorrelation: link.medianCorrelation,
      };
    })
    .sort((a: any, b: any) => b.medianCorrelation - a.medianCorrelation);

  return (
    <div className="px-10 py-4 m-auto ">
      {viewType === "Edge Table" && (
        <Table
          rowKey="id"
          scroll={{ x: 400 }}
          columns={EdgeTableColumns}
          dataSource={DataSource}
          showSorterTooltip={true}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: store) => ({
  values: state.query.currentQueryDetails,
});

export default connect(mapStateToProps)(TableView);
