import Axios from "axios";
interface queryData {
  geneOrDrug: string;
  pertType: string;
  isActivator: boolean;
  cellLine: string | null;
  absoluteSimilarity: number;
  nodeLimit: number;
}

function useAPI() {
  function fetchAutoCompleteData(
    input: string,
    callback?: (arg: any) => void | Promise<void>
  ) {
    Axios.post("/api/auto-complete", {
      keyword: input,
    })
      .then((res: any) => {
        callback && callback(res);
      })
      .catch((err: any) => {
        callback && callback(null);
      });
  }

  function fetchGeneOrDrugData(
    queryData: queryData,
    callback?: (arg: any) => void | Promise<void>
  ) {
    Axios.post("/api/query", queryData)
      .then((res: any) => {
        callback && callback(res);
      })
      .catch((err: any) => {
        callback && callback(null);
      });
  }

  function getCellLineData(
    keyword: string,
    pertType: string,
    callback?: (arg: any) => void | Promise<void>
  ) {
    Axios.post("/api/get-cell-lines", {
      keyword,
      pertType,
    })
      .then((res: any) => {
        callback && callback(res);
      })
      .catch((err: any) => {
        callback && callback([]);
      });
  }

  return {
    fetchAutoCompleteData,
    fetchGeneOrDrugData,
    getCellLineData,
  };
}

export default useAPI;
